import { DataViewHierarchyLevel, DataViewMatrixNode } from "@zebrabi/matrix-data";


import { SortDirection, CategoryOrder, CumulativeMinMaxAndOffsets, MinMax, IHierarchical, DataPointType, Sums, AbsoluteChart, CategoryTreeNode, ValueChart } from "../definitions";
import { VarianceSettings } from "../settings/varianceSettings";
import { TopNSetting } from "../settings/topNSetting";
import { Extremes } from "./extremes";
import { ViewModel } from "../settings/viewModel";
import { DataProperty, NORMAL, ShowTotals, TopNType, EMPTY, BOLD, ColumnFormat } from "../library/constants";
import { DataPoint } from "./dataPoint";
import { Extreme } from "./extreme";
import { ChartGroup } from "./chartGroup";
import { LevelCategoryWidth } from "./../interfaces";
import { Position } from "@zebrabi/legacy-library-common/interfaces";
import * as d3 from "d3";
import * as calculations from "./../calculations";
import * as drawing from "./../library/drawing";
import * as matrixViewModelHelpers from "../settings/matrixViewModelHelpers";
import { getAdditionalMeasurePropertyFromIndex, isRelativeMeasure } from "../helpers";
import { isAdditionalMeasure } from "../library/helpers";

export class ChartHierarchy implements IHierarchical {
    public totalDataPoint: DataPoint;
    public otherHierarchy: ChartHierarchy;
    public grandTotalDataPoint: DataPoint;
    public otherDataPoint: DataPoint;
    public valueCumulative: number;
    public referenceValueCumulative: number;
    public secondReferenceValueCumulative: number;
    public thirdReferenceValueCumulative: number;
    public fourthReferenceValueCumulative: number;
    public fifthReferenceValueCumulative: number;
    public sixthReferenceValueCumulative: number;
    public seventhReferenceValueCumulative: number;
    public absoluteCumulative: number;
    public secondAbsoluteCumulative: number;
    public thirdAbsoluteCumulative: number;
    public fourthAbsoluteCumulative: number;
    public fifthAbsoluteCumulative: number;
    public sixthAbsoluteCumulative: number;
    public seventhAbsoluteCumulative: number;
    private showTotals: ShowTotals;
    private _dataPoints: DataPoint[];
    public chartHierarchies: ChartHierarchy[];
    public isPlotted: boolean;
    public shouldDrawOther: boolean;
    public calculationsFinished: boolean;
    public plottedHeader: boolean;
    private settings: VarianceSettings;
    // public selectionId: ISelectionId;
    public plotYPosition?: number;
    public connectingLinePositions: Position[];
    public isInvertedTotal: boolean = false;

    constructor(private viewModel: ViewModel, public isHierarchical: boolean, public isGrandTotal: boolean, public category: string, public fullCategory: string, public parent: IHierarchical,
        public node: DataViewMatrixNode, public hierarchyLevels: DataViewHierarchyLevel[], public level: number, public firstGroupParent: ChartGroup, hierarchyIdentity: string, isFormula: boolean) {
        this._dataPoints = [];
        this.chartHierarchies = [];
        this.settings = viewModel.settings;
        this.showTotals = this.settings.showTotals;
        this.shouldDrawOther = false;
        this.calculationsFinished = false;
        this.plottedHeader = false;
        if (isHierarchical && !this.totalDataPoint) {
            // let id = matrixViewModelHelpers.getSelectionId(this.node, this.hierarchyLevels, this.getParents(), this.settings.host);
            let type = isFormula ? DataPointType.FormulaTotal : DataPointType.Total;
            this.isInvertedTotal = this.settings.isInvertedTotal(category, hierarchyIdentity);
            if (this.parent instanceof ChartHierarchy) {
                this.isInvertedTotal = this.isInvertedTotal !== (<ChartHierarchy>this.parent).isInvertedTotal;
            }
            this.totalDataPoint = new DataPoint(null, null, null, null, null, null, null, null, [], [], [], category, fullCategory, null, null, type, this.viewModel, level, this, hierarchyIdentity);
        }
        // this.selectionId = matrixViewModelHelpers.getSelectionId(this.node, this.hierarchyLevels, this.getHierarchyParents(), this.settings.host);
        this.isPlotted = false;
        this.connectingLinePositions = [];
    }

    getPlotYPosition(): number {
        if (this.hasChildHierarchies) {
            let arr = []
            this.chartHierarchies.forEach(ch => {
                arr.push(ch.getPlotYPosition());
            })
            arr.push(this.plotYPosition);
            return Math.min(...arr.filter(u => u !== undefined));
        } else {
            return this.plotYPosition;
        }
    }

    get hasChildHierarchies(): boolean {
        return this.chartHierarchies.length > 0;
    }

    public allDataPointsPlotted(): boolean {
        return this.dataPoints().every(dp => dp.isPlotted);
    }

    public anyDataPointPlotted(): boolean {
        return this.dataPoints().some(dp => dp.isPlotted);
    }

    public dataPoints(alsoCollapsed = false, alsoSinglePointHierarchies = false): DataPoint[] {
        if (this.isGrandTotal) {
            return [this.grandTotalDataPoint];
        }
        else if (this.totalDataPoint == null) {
            return this.shouldDrawOther ? [...this._dataPoints, this.otherDataPoint] : this._dataPoints;
        }
        else if (this.totalDataPoint.isCollapsed && !alsoCollapsed) {
            return [this.totalDataPoint];
        }
        else if (this.oneDataPointWithSameCategoryAsTotal()) {
            if (alsoSinglePointHierarchies) {
                return [this.totalDataPoint, this._dataPoints[0]];
            }
            else {
                return [this.totalDataPoint];
            }
        }
        else if (this.showTotals === ShowTotals.Above || this.showTotals === ShowTotals.AboveHideValues) {
            return this.shouldDrawOther ? [this.totalDataPoint, ...this._dataPoints, this.otherDataPoint] : [this.totalDataPoint, ...this._dataPoints];
        }
        else if (this.showTotals === ShowTotals.Below) {
            return this.shouldDrawOther ? [...this._dataPoints, this.otherDataPoint, this.totalDataPoint] : [...this._dataPoints, this.totalDataPoint];
        }
    }

    public addHierarchy(hierarchy: ChartHierarchy) {
        this.chartHierarchies.push(hierarchy);
    }

    public oneDataPointWithSameCategoryAsTotal() {
        if (this.hasChildHierarchies) {
            return this.chartHierarchies.length === 1 && this.totalDataPoint && this.chartHierarchies[0].totalDataPoint && this.chartHierarchies[0].totalDataPoint.category === this.totalDataPoint.category;
        }
        return this._dataPoints.length === 1 && this.totalDataPoint && this._dataPoints[0].category === this.totalDataPoint.category && !this.shouldDrawOther;
    }

    public addDataPoint(dataPoint: DataPoint) {
        this._dataPoints.push(dataPoint);
    }

    public addTotalDataPointValues(value: number, referenceValue: number, secondReferenceValue: number, thirdReferenceValue: number, fourthReferenceValue: number, fifthReferenceValue: number, sixthReferenceValue: number, seventhReferenceValue: number, additionalMeasures: number[], tooltipsValues: string[]) {
        this.totalDataPoint.value = value;
        this.totalDataPoint.referenceValue = referenceValue;
        this.totalDataPoint.secondReferenceValue = secondReferenceValue;
        this.totalDataPoint.thirdReferenceValue = thirdReferenceValue;
        this.totalDataPoint.fourthReferenceValue = fourthReferenceValue;
        this.totalDataPoint.fifthReferenceValue = fifthReferenceValue;
        this.totalDataPoint.sixthReferenceValue = sixthReferenceValue;
        this.totalDataPoint.seventhReferenceValue = seventhReferenceValue;
        this.totalDataPoint.additionalMeasures = additionalMeasures;
        this.totalDataPoint.tooltipsValues = tooltipsValues;
    }

    public setDataPoints(points: DataPoint[]): void {
        this._dataPoints = points;
    }

    public numberOfAllPoints(): number {
        if (this.hasChildHierarchies) {
            return 1 + this.chartHierarchies.reduce((result, h) => {
                return result + h.numberOfAllPoints();
            }, 0);
        }
        return this.dataPoints().length;
    }

    public numberOfPoints(): number {
        return this._dataPoints.length;
    }

    public getAllChartHierarchies(): ChartHierarchy[] {
        let result: ChartHierarchy[];
        if (this.settings.showTotals === ShowTotals.Above || this.settings.showTotals === ShowTotals.AboveHideValues) {
            result = [<ChartHierarchy>this];
        }
        else {
            result = [];
        }
        if (this.hasChildHierarchies && !this.isCollapsed()) {
            if (this.hasNonRepeatingHiearchiesUnderneath()) {
                this.chartHierarchies.map(h => {
                    result = result.concat(h.getAllChartHierarchies());
                });
            }
            if (this.settings.showTotals === ShowTotals.Above || this.settings.showTotals === ShowTotals.AboveHideValues) {
                return result;
            }
            else {
                return result.concat(<ChartHierarchy>this);
            }
        }
        else {
            return [<ChartHierarchy>this];
        }
    }

    public hasNonRepeatingHiearchiesUnderneath(): boolean {
        if (!this.hasChildHierarchies) {
            if (this.oneDataPointWithSameCategoryAsTotal()) {
                return false;
            }
            return this._dataPoints.length > 0;
        }
        if (this.chartHierarchies.length > 1) {
            return true;
        }
        else if (this.chartHierarchies.length === 1 && this.totalDataPoint.category === this.chartHierarchies[0].totalDataPoint.category) {
            return this.chartHierarchies[0].hasNonRepeatingHiearchiesUnderneath();
        }
        return true;
    }

    public sort(direction: SortDirection, dataProperty: DataProperty) {
        if (this.hasChildHierarchies) {
            this.chartHierarchies.sort((a, b) => {
                return direction === SortDirection.Descending ?
                    b.getTotal(dataProperty) - a.getTotal(dataProperty) :
                    a.getTotal(dataProperty) - b.getTotal(dataProperty);
            });
            this.chartHierarchies.map(h => h.sort(direction, dataProperty));
        }
        else {
            this._dataPoints.sort((a, b) => {
                let valueA = a.getValue(dataProperty);
                let valueB = b.getValue(dataProperty);
                if (direction === SortDirection.Descending) {
                    if (!valueA && valueA !== 0) {
                        return 1;
                    }
                    if (!valueB && valueB !== 0) {
                        return -1;
                    }
                    return valueB - valueA;
                }
                else {
                    if (!valueA && valueA !== 0) {
                        return -1;
                    }
                    if (!valueB && valueB !== 0) {
                        return 1;
                    }
                    return valueA - valueB;
                }
            });
        }
    }

    public sortByReferenceCategories(referenceCategories: string[], category: string) {
        if (this.hasChildHierarchies) {
            this.chartHierarchies.forEach(h => h.sortByReferenceCategories(referenceCategories, category));
        }
        else {
            this._dataPoints.sort((a, b) => referenceCategories.indexOf(a[category]) - referenceCategories.indexOf(b[category]));
        }
    }

    public sortByCategoryTree(categoryTree: CategoryTreeNode, category: string) {
        let categories = categoryTree.children.map(c => c.category);
        if (this.hasChildHierarchies) {
            this.chartHierarchies.sort((a, b) => {
                if (a.isGrandTotal) {
                    return 1;
                }
                if (b.isGrandTotal) {
                    return -1;
                }
                return categories.indexOf(a.getCategory()) - categories.indexOf(b.getCategory());
            });

            this.chartHierarchies.filter(h => !h.isGrandTotal).forEach((h, i) => h.sortByCategoryTree(categoryTree.children[i], category));
        }
        else {
            this._dataPoints.sort((a, b) => categories.indexOf(a[category]) - categories.indexOf(b[category]));
        }
    }

    public sortByReferenceHiearchies(referenceHierarchies: string[]) {
        if (this.hasChildHierarchies) {
            this.chartHierarchies.sort((a, b) => referenceHierarchies.indexOf(a.getCategory()) - referenceHierarchies.indexOf(b.getCategory()));
            this.chartHierarchies.forEach(hierarchy => hierarchy.sortByReferenceHiearchies(referenceHierarchies));
        }
    }

    public cutTopNByCategory(setting: TopNSetting, locale: string, percentageFormat: string, isHierarchical: boolean, summedCategoriesTree: CategoryTreeNode, isFocusMode: boolean) {
        // if this topN setting is for a level that is not present in our viewmodel
        if (this.hierarchyLevels.length <= setting.level || this.otherDataPoint && this._dataPoints.length === 0) {
            return;
        }
        // if we are not at that level yet
        else if (this.level + 1 < setting.level) {
            this.chartHierarchies.forEach(h => h.cutTopNByCategory(setting, locale, percentageFormat, isHierarchical, summedCategoriesTree.children.find(c => c.category === this.fullCategory), isFocusMode));
        }
        else {
            //
            let points: DataPoint[] = [];
            //if there are n levels and we are on n-1th, use _dataPoints
            if (this.hierarchyLevels.length === setting.level + 1) {
                points = this._dataPoints;
                this._dataPoints = this.calculateTopNByCategory(setting, locale, percentageFormat, points, isHierarchical, summedCategoriesTree.children.find(c => c.category === this.fullCategory), false, this, isFocusMode);
            }
            //else use total DataPoints from hierarchy on lower level;
            else {
                this.chartHierarchies.forEach(ch => {
                    points.push(ch.totalDataPoint);
                })
                let emptyNode: DataViewMatrixNode = {
                    value: EMPTY,
                    children: [],
                    // identity: null,
                };
                let parentGroup: IHierarchical = this;
                while (parentGroup && !(parentGroup instanceof ChartGroup)) {
                    parentGroup = parentGroup.parent;
                }
                this.otherHierarchy = new ChartHierarchy(this.viewModel, false, false, this.settings.topNOtherLabel, EMPTY, this, emptyNode, this.hierarchyLevels, this.level + 1, <ChartGroup>parentGroup, null, false);
                let filteredPoints = this.calculateTopNByCategory(setting, locale, percentageFormat, points, isHierarchical, summedCategoriesTree.children.find(c => c.category === this.fullCategory), true, this.otherHierarchy, isFocusMode);
                //hide hierarchies which dont have its total datapoint in filteredPoints
                this.chartHierarchies = this.chartHierarchies.filter(ch => filteredPoints.map(fp => fp.fullCategory).indexOf(ch.totalDataPoint.fullCategory) > -1);
                this.chartHierarchies.push(this.otherHierarchy);
            }
        }
    }


    public calculateTopNByCategory(setting: TopNSetting, locale: string, percentageFormat: string, points: DataPoint[], isHierarchical: boolean, summedCategoriesTree: CategoryTreeNode, isHigherLevelOther: boolean, hierarchy: ChartHierarchy, isFocusMode: boolean) {
        let n = Math.min(isFocusMode ? setting.numberInFocusMode : setting.number, points.length);
        hierarchy.shouldDrawOther = true;
        if (n === points.length) {
            hierarchy.shouldDrawOther = false;
        }
        let filteredValues = summedCategoriesTree.children.filter(co => points.map(dp => isHierarchical ? dp.fullCategory : dp.category).indexOf(co.category) > -1);
        let sortedValues: CategoryOrder[] = [];
        if (setting.type === TopNType.Top) {
            sortedValues = filteredValues.sort((a, b) => b.result - a.result);
        } else if (setting.type === TopNType.Bottom) {
            sortedValues = filteredValues.sort((a, b) => a.result - b.result);
        } else {
            sortedValues = filteredValues.sort((a, b) => Math.abs(b.result) - Math.abs(a.result));
        }
        let topNvalues = sortedValues.slice(0, n);
        let topNCategories = topNvalues.map(co => co.category);
        let topNDataPoints = points.filter(dp => topNCategories.indexOf(isHierarchical ? dp.fullCategory : dp.category) > -1);

        let otherDPs = points.filter(function (e) { return this.indexOf(e) < 0; }, topNDataPoints);
        points = points.filter(function (e) { return this.indexOf(e) >= 0; }, topNDataPoints);
        let otherDPadditionalMeasures: number[] = [
            hierarchy.sum(DataProperty.AdditionalMeasure1, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure2, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure3, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure4, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure5, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure6, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure7, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure8, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure9, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure10, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure11, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure12, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure13, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure14, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure15, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure16, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure17, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure18, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure19, true, otherDPs, isHigherLevelOther),
            hierarchy.sum(DataProperty.AdditionalMeasure20, true, otherDPs, isHigherLevelOther),
        ];
        if (points.length > 0) {
            let fullCategory = points[0].getParentCategories() + this.settings.topNOtherLabel;
            hierarchy.otherDataPoint = new DataPoint(hierarchy.sum(DataProperty.Value, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.ReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.SecondReferenceValue, true, otherDPs, isHigherLevelOther),
                hierarchy.sum(DataProperty.ThirdReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.FourthReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.FifthReferenceValue, true, otherDPs, isHigherLevelOther),
                hierarchy.sum(DataProperty.SixthReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.SeventhReferenceValue, true, otherDPs, isHigherLevelOther), otherDPadditionalMeasures, [], [], this.settings.topNOtherLabel, fullCategory, null, null, isHigherLevelOther ? DataPointType.OtherTotal : DataPointType.Other, hierarchy.viewModel, points[0].level, hierarchy, null);
        }
        else {
            hierarchy.otherDataPoint = new DataPoint(hierarchy.sum(DataProperty.Value, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.ReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.SecondReferenceValue, true, otherDPs, isHigherLevelOther),
                hierarchy.sum(DataProperty.ThirdReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.FourthReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.FifthReferenceValue, true, otherDPs, isHigherLevelOther),
                hierarchy.sum(DataProperty.SixthReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.SeventhReferenceValue, true, otherDPs, isHigherLevelOther), otherDPadditionalMeasures, [], [], this.settings.topNOtherLabel, this.settings.topNOtherLabel, null, null, isHigherLevelOther ? DataPointType.OtherTotal : DataPointType.Other, hierarchy.viewModel, hierarchy.level + 1, hierarchy, null);
        }
        hierarchy.otherDataPoint.calculate(locale, percentageFormat);
        hierarchy.otherDataPoint.associatedDataPoints = otherDPs;
        hierarchy.calculationsFinished = true;

        return points;
    }

    public getTotal(dataProperty: DataProperty, considerSkippingFormula?: boolean) {
        if (this.totalDataPoint?.isFormula()) {
            if (considerSkippingFormula && this.totalDataPoint.isSkipped) {
                return null;
            } else {
                return this.totalDataPoint.getValue(dataProperty);
            }
        }
        let cf = this.settings.getColumnSettings(dataProperty)?.format;
        if (this.totalDataPoint && isAdditionalMeasure(dataProperty) && (cf === ColumnFormat.Percent || cf === ColumnFormat.RelativeDifference || cf === ColumnFormat.AbsoluteDifference)) {
            return this.totalDataPoint.getValue(dataProperty);
        }
        if (dataProperty === DataProperty.RelativeDifference || dataProperty === DataProperty.SecondRelativeDifference || dataProperty === DataProperty.ThirdRelativeDifference ||
            dataProperty === DataProperty.FourthRelativeDifference || dataProperty === DataProperty.FifthRelativeDifference || dataProperty === DataProperty.SixthRelativeDifference || dataProperty === DataProperty.SeventhRelativeDifference) {
            let valueSum = this.sum(DataProperty.Value, true, null, false);

            let referenceProperty: DataProperty;
            if (dataProperty === DataProperty.RelativeDifference) {
                referenceProperty = DataProperty.ReferenceValue;
            } else if (dataProperty === DataProperty.SecondRelativeDifference) {
                referenceProperty = DataProperty.SecondReferenceValue;
            } else if (dataProperty === DataProperty.ThirdRelativeDifference) {
                referenceProperty = DataProperty.ThirdReferenceValue;
            } else if (dataProperty === DataProperty.FourthRelativeDifference) {
                referenceProperty = DataProperty.FourthReferenceValue;
            } else if (dataProperty === DataProperty.FifthRelativeDifference) {
                referenceProperty = DataProperty.FifthReferenceValue;
            } else if (dataProperty === DataProperty.SixthRelativeDifference) {
                referenceProperty = DataProperty.SixthReferenceValue;
            } else if (dataProperty === DataProperty.SeventhRelativeDifference) {
                referenceProperty = DataProperty.SeventhReferenceValue;
            }

            let referenceSum = this.sum(referenceProperty, true, null, false);
            if (referenceSum !== 0 && referenceSum != null && valueSum != null) {
                return (valueSum - referenceSum) / Math.abs(referenceSum) * 100;
            }
        }
        else {
            return this.sum(dataProperty, true, null, false);
        }
        return null;
    }

    public sum(dataProperty: DataProperty, shouldUseInvertedData: boolean, dataPoints: DataPoint[], isHigherLevelOther: boolean): number {
        if (this.hasChildHierarchies && !isHigherLevelOther) {
            let results = this.chartHierarchies.filter(h => !h.totalDataPoint || !h.totalDataPoint.isSkipped).map(h => h.sum(dataProperty, shouldUseInvertedData, dataPoints, isHigherLevelOther));
            if (results.every(v => v === null)) {
                return null;
            }
            return d3.sum(results);
        }
        else {
            let filteredDataPoints: DataPoint[] = [];
            if (!dataPoints) {
                dataPoints = this._dataPoints;
            }
            if (!this.viewModel.hasHierarchy && dataPoints.some(d => d.isFlatResult())) {
                let index = -1;
                for (let i = dataPoints.length - 1; i >= 0; i--) {
                    let point = dataPoints[i];
                    if (point.isFlatResult()) {
                        index = i;
                        break;
                    }
                }
                if (index !== -1) {
                    filteredDataPoints = dataPoints.slice(index, dataPoints.length);
                }
                else {
                    filteredDataPoints = [...dataPoints];
                }
            }
            else {
                filteredDataPoints = [...dataPoints];
            }
            if (this.shouldDrawOther && this.calculationsFinished && dataPoints.length) {
                filteredDataPoints.push(this.otherDataPoint);
            }
            if (this.totalDataPoint?.isFormula()) {
                return this.totalDataPoint.isSkipped ? null : this.totalDataPoint.getValue(dataProperty);
            }
            let filteredDataPointValues = filteredDataPoints.map(d => {
                if (d.isSkipped && !this.oneDataPointWithSameCategoryAsTotal()) {
                    return null;
                }
                let value = d.getValue(dataProperty);
                if (value === null || value === undefined || isNaN(value)) {
                    return null;
                }
                return value * (shouldUseInvertedData && d.isInverted ? -1 : 1);
            });
            if (filteredDataPointValues.filter(v => v !== null).length === 0) {
                return null;
            }

            return d3.sum(filteredDataPointValues);;
        }
    }

    public recalculateSubtotals(): void {
        if (this.isHierarchical) {
            this.chartHierarchies.forEach(ch => ch.recalculateSubtotals())
        }
        if (this.totalDataPoint) {
            this.totalDataPoint.value = this.getTotal(DataProperty.Value);
            this.totalDataPoint.referenceValue = this.getTotal(DataProperty.ReferenceValue);
            this.totalDataPoint.secondReferenceValue = this.getTotal(DataProperty.SecondReferenceValue);
            this.totalDataPoint.thirdReferenceValue = this.getTotal(DataProperty.ThirdReferenceValue);
            this.totalDataPoint.fourthReferenceValue = this.getTotal(DataProperty.FourthReferenceValue);
            this.totalDataPoint.fifthReferenceValue = this.getTotal(DataProperty.FifthReferenceValue);
            this.totalDataPoint.sixthReferenceValue = this.getTotal(DataProperty.SixthReferenceValue);
            this.totalDataPoint.seventhReferenceValue = this.getTotal(DataProperty.SeventhReferenceValue);
            this.totalDataPoint.additionalMeasures = this.totalDataPoint.additionalMeasures.map((v, ix) => this.getTotal(getAdditionalMeasurePropertyFromIndex(ix)));
        }
    }

    public getDataPoint(index: number): DataPoint {
        return this.addOtherDataPointIfNeeded(this._dataPoints)[index];
    }

    public getCategory() {
        if (this.isHierarchical) {
            return this.totalDataPoint.fullCategory;
        }
        return EMPTY;
    }

    public getHierarchyCategories(category: string): string[] {
        if (this.hasChildHierarchies) {
            let result = [this.totalDataPoint[category]];
            for (let i = 0; i < this.chartHierarchies.length; i++) {
                let curr = this.chartHierarchies[i].getHierarchyCategories(category);
                result = result.concat(curr);
            }
            return result;
        }
        else {
            if (this.totalDataPoint) {
                return [this.totalDataPoint[category]];
            }
            else {
                return [];
            }
        }
    }

    public getCategories(category: string): string[] {
        if (this.hasChildHierarchies) {
            return this.chartHierarchies.reduce((categories, h) => categories.concat(h.getCategories(category)), []);
        }
        return this.dataPoints().map(d => d[category]);
    }

    public addOtherDataPointIfNeeded(dataPoints: DataPoint[]) {
        if (this.shouldDrawOther && dataPoints.indexOf(this.otherDataPoint) < 0) {
            this.settings.topNOtherShown = true;
            return [...dataPoints, this.otherDataPoint];
        }
        return dataPoints;
    }

    public calculate(locale: string, percentageFormat: string, sums: Sums): Extremes {
        let minsAndMaxes: Extremes[];
        if (this.hasChildHierarchies) {
            minsAndMaxes = this.chartHierarchies.map(h => h.calculate(locale, percentageFormat, sums));
            minsAndMaxes = minsAndMaxes.concat(this.totalDataPoint.calculate(locale, percentageFormat));
        }
        else {
            minsAndMaxes = this.dataPoints(true).map(dataPoint => dataPoint.calculate(locale, percentageFormat));
            let value = this.sum(DataProperty.Value, true, null, false);
            if (value !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.value += value;
            }
            let referenceValue = this.sum(DataProperty.ReferenceValue, true, null, false);
            if (referenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.referenceValue += referenceValue;
            }
            let secondReferenceValue = this.sum(DataProperty.SecondReferenceValue, true, null, false);
            if (secondReferenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.secondReferenceValue += secondReferenceValue;
            }
            let thirdReferenceValue = this.sum(DataProperty.ThirdReferenceValue, true, null, false);
            if (thirdReferenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.thirdReferenceValue += thirdReferenceValue;
            }
            let fourthReferenceValue = this.sum(DataProperty.FourthReferenceValue, true, null, false);
            if (fourthReferenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.fourthReferenceValue += fourthReferenceValue;
            }
            let fifthReferenceValue = this.sum(DataProperty.FifthReferenceValue, true, null, false);
            if (fifthReferenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.fifthReferenceValue += fifthReferenceValue;
            }
            let sixthReferenceValue = this.sum(DataProperty.SixthReferenceValue, true, null, false);
            if (sixthReferenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.sixthReferenceValue += sixthReferenceValue;
            }
            let seventhReferenceValue = this.sum(DataProperty.SeventhReferenceValue, true, null, false);
            if (seventhReferenceValue !== null && (!this.totalDataPoint?.isResult || !this.oneDataPointWithSameCategoryAsTotal())) {
                sums.seventhReferenceValue += seventhReferenceValue;
            }
        }
        if (this.isHierarchical && !this.totalDataPoint?.isRowDisplayUnitPercent()) {
            this.setAutomaticInvertsAndResults();
            if (this.settings.hasInvertOrResultOrSkip() && this.settings.showTotals !== ShowTotals.AboveHideValues) {
                if (this.totalDataPoint.isResult && (!this.oneDataPointWithSameCategoryAsTotal() || this.totalDataPoint.isFormula())) {
                    this.totalDataPoint.value = sums.value;
                    this.totalDataPoint.referenceValue = sums.referenceValue;
                    this.totalDataPoint.secondReferenceValue = sums.secondReferenceValue;
                    this.totalDataPoint.thirdReferenceValue = sums.thirdReferenceValue;
                    this.totalDataPoint.fourthReferenceValue = sums.fourthReferenceValue;
                    this.totalDataPoint.fifthReferenceValue = sums.fifthReferenceValue;
                    this.totalDataPoint.sixthReferenceValue = sums.sixthReferenceValue;
                    this.totalDataPoint.seventhReferenceValue = sums.seventhReferenceValue;
                }
                else {
                    let shouldInvertContributions = this.shouldInvertContributions();
                    if (!this.totalDataPoint.isSkipped) {
                        this.totalDataPoint.value = this.sum(DataProperty.Value, shouldInvertContributions, null, false);
                        this.totalDataPoint.referenceValue = this.sum(DataProperty.ReferenceValue, shouldInvertContributions, null, false);
                        this.totalDataPoint.secondReferenceValue = this.sum(DataProperty.SecondReferenceValue, shouldInvertContributions, null, false);
                        this.totalDataPoint.thirdReferenceValue = this.sum(DataProperty.ThirdReferenceValue, shouldInvertContributions, null, false);
                        this.totalDataPoint.fourthReferenceValue = this.sum(DataProperty.FourthReferenceValue, shouldInvertContributions, null, false);
                        this.totalDataPoint.fifthReferenceValue = this.sum(DataProperty.FifthReferenceValue, shouldInvertContributions, null, false);
                        this.totalDataPoint.sixthReferenceValue = this.sum(DataProperty.SixthReferenceValue, shouldInvertContributions, null, false);
                        this.totalDataPoint.seventhReferenceValue = this.sum(DataProperty.SeventhReferenceValue, shouldInvertContributions, null, false);
                    }
                }
            }
            else if (this.totalDataPoint.value === null) {
                let shouldInvertContributions = false;
                this.totalDataPoint.value = this.sum(DataProperty.Value, shouldInvertContributions, null, false);
                this.totalDataPoint.referenceValue = this.sum(DataProperty.ReferenceValue, shouldInvertContributions, null, false);
                this.totalDataPoint.secondReferenceValue = this.sum(DataProperty.SecondReferenceValue, shouldInvertContributions, null, false);
                this.totalDataPoint.thirdReferenceValue = this.sum(DataProperty.ThirdReferenceValue, shouldInvertContributions, null, false);
                this.totalDataPoint.fourthReferenceValue = this.sum(DataProperty.FourthReferenceValue, shouldInvertContributions, null, false);
                this.totalDataPoint.fifthReferenceValue = this.sum(DataProperty.FifthReferenceValue, shouldInvertContributions, null, false);
                this.totalDataPoint.sixthReferenceValue = this.sum(DataProperty.SixthReferenceValue, shouldInvertContributions, null, false);
                this.totalDataPoint.seventhReferenceValue = this.sum(DataProperty.SeventhReferenceValue, shouldInvertContributions, null, false);
            }

            this.totalDataPoint.calculate(locale, percentageFormat);
            minsAndMaxes = minsAndMaxes.concat(this.totalDataPoint.calculate(locale, percentageFormat));
        }
        if (this.oneDataPointWithSameCategoryAsTotal()) {
            if (!this.hasChildHierarchies) {
                this._dataPoints[0].calculate(locale, percentageFormat);
            }
        }
        return calculations.reduceMinsAndMaxes(minsAndMaxes);
    }

    public setTopN(setting: TopNSetting, locale: string, percentageFormat: string, isFocusMode: boolean) {
        // if this topN setting is for a level that is not present in our viewmodel
        if (this.hierarchyLevels.length <= setting.level || this.otherDataPoint && this._dataPoints.length === 0) {
            return;
        }
        // if we are not at that level yet
        else if (this.level + 1 < setting.level) {
            this.chartHierarchies.forEach(h => h.setTopN(setting, locale, percentageFormat, isFocusMode));
        }
        else {

            let points: DataPoint[] = [];
            //if there are n levels and we are on n-1th, use _dataPoints
            if (this.hierarchyLevels.length === setting.level + 1) {
                points = this._dataPoints;
                this._dataPoints = this.calculateTopN(setting, locale, percentageFormat, points, false, this, isFocusMode);
            }
            //else use total DataPoints from hierarchy on lower level;
            else {
                this.chartHierarchies.forEach(ch => {
                    points.push(ch.totalDataPoint);
                })
                let emptyNode: DataViewMatrixNode = {
                    value: EMPTY,
                    children: [],
                    // identity: null,
                };
                let parentGroup: IHierarchical = this;
                while (parentGroup && !(parentGroup instanceof ChartGroup)) {
                    parentGroup = parentGroup.parent;
                }
                this.otherHierarchy = new ChartHierarchy(this.viewModel, false, false, this.settings.topNOtherLabel, EMPTY, this, emptyNode, this.hierarchyLevels, this.level + 1, <ChartGroup>parentGroup, null, false);

                let filteredPoints = this.calculateTopN(setting, locale, percentageFormat, points, true, this.otherHierarchy, isFocusMode);
                //hide hierarchies which dont have its total datapoint in filteredPoints
                this.chartHierarchies = this.chartHierarchies.filter(ch => filteredPoints.map(fp => fp.fullCategory).indexOf(ch.totalDataPoint.fullCategory) > -1);
                this.chartHierarchies.push(this.otherHierarchy);
            }
        }
    }

    public calculateTopN(setting: TopNSetting, locale: string, percentageFormat: string, points: DataPoint[], isHigherLevelOther: boolean, hierarchy: ChartHierarchy, isFocusMode: boolean): DataPoint[] {
        let n = Math.min(isFocusMode ? setting.numberInFocusMode : setting.number, points.length);
        hierarchy.shouldDrawOther = true;
        if (n === points.length) {
            hierarchy.shouldDrawOther = false;
        }
        let topNarray = [];
        if (setting.type === TopNType.Top) {
            topNarray = points.concat().sort((a, b) => (b.getValue(setting.dataProperty)) - (a.getValue(setting.dataProperty))).slice(0, n);
        }
        else if (setting.type === TopNType.TopAndBottom) {
            topNarray = points.concat().sort((a, b) => Math.abs(b.getValue(setting.dataProperty)) - Math.abs(a.getValue(setting.dataProperty))).slice(0, n);
        } else {
            topNarray = points.concat().sort((a, b) => (a.getValue(setting.dataProperty)) - (b.getValue(setting.dataProperty))).slice(0, n);
        }
        let otherDPs = points.filter(function (e) { return this.indexOf(e) < 0; }, topNarray);
        points = points.filter(function (e) { return this.indexOf(e) >= 0; }, topNarray);
        let otherDPadditionalMeasures: number[] = [];
        for (let index = 0; index < this.viewModel.additionalMeasures.length; index++) {
            let dp = getAdditionalMeasurePropertyFromIndex(index);
            otherDPadditionalMeasures.push(hierarchy.sum(dp, true, otherDPs, isHigherLevelOther));
        }
        if (points.length > 0) {
            let fullCategory = points[0].getParentCategories() + this.settings.topNOtherLabel;
            hierarchy.otherDataPoint = new DataPoint(hierarchy.sum(DataProperty.Value, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.ReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.SecondReferenceValue, true, otherDPs, isHigherLevelOther),
                hierarchy.sum(DataProperty.ThirdReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.FourthReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.FifthReferenceValue, true, otherDPs, isHigherLevelOther),
                hierarchy.sum(DataProperty.SixthReferenceValue, true, otherDPs, isHigherLevelOther), hierarchy.sum(DataProperty.SeventhReferenceValue, true, otherDPs, isHigherLevelOther), otherDPadditionalMeasures, [], [], this.settings.topNOtherLabel, fullCategory, null, null, isHigherLevelOther ? DataPointType.OtherTotal : DataPointType.Other, hierarchy.viewModel, points[0].level, hierarchy, points[0].hierarchyIdentity);
            hierarchy.otherDataPoint.calculate(locale, percentageFormat);
            hierarchy.otherDataPoint.associatedDataPoints = otherDPs;
            hierarchy.calculationsFinished = true;
        }

        return points;
    }

    // tslint:disable-next-line: max-func-body-length
    public calculateCumulative(valueCumulative: number, referenceValueCumulative: number, secondReferenceValueCumulative: number, thirdReferenceValueCumulative: number, fourthReferenceValueCumulative: number, fifthReferenceValueCumulative: number, sixthReferenceValueCumulative: number, seventhReferenceValueCumulative: number,
        absoluteCumulative: number, secondAbsoluteCumulative: number, thirdAbsoluteCumulative: number, fourthAbsoluteCumulative: number, fifthAbsoluteCumulative: number, sixthAbsoluteCumulative: number, seventhAbsoluteCumulative: number,
        locale: string, percentageFormat: string, maximumExpandedHierarchyLevel: number, maximumExpandedGroupLevel: number): CumulativeMinMaxAndOffsets {
        let valueResult = new Extreme(0, 0, 0, 0);
        let referenceValueResult = new Extreme(0, 0, 0, 0);
        let secondReferenceValueResult = new Extreme(0, 0, 0, 0);
        let thirdReferenceValueResult = new Extreme(0, 0, 0, 0);
        let fourthReferenceValueResult = new Extreme(0, 0, 0, 0);
        let fifthReferenceValueResult = new Extreme(0, 0, 0, 0);
        let sixthReferenceValueResult = new Extreme(0, 0, 0, 0);
        let seventhReferenceValueResult = new Extreme(0, 0, 0, 0);
        let absoluteResult = new Extreme(0, 0, 0, 0);
        let secondAbsoluteResult = new Extreme(0, 0, 0, 0);
        let thirdAbsoluteResult = new Extreme(0, 0, 0, 0);
        let fourthAbsoluteResult = new Extreme(0, 0, 0, 0);
        let fifthAbsoluteResult = new Extreme(0, 0, 0, 0);
        let sixthAbsoluteResult = new Extreme(0, 0, 0, 0);
        let seventhAbsoluteResult = new Extreme(0, 0, 0, 0);
        let startingValueCumulative = valueCumulative;
        let startingReferenceValueCumulative = referenceValueCumulative;
        let startingSecondReferenceValueCumulative = secondReferenceValueCumulative;
        let startingThirdReferenceValueCumulative = thirdReferenceValueCumulative;
        let startingFourthReferenceValueCumulative = fourthReferenceValueCumulative;
        let startingFifthReferenceValueCumulative = fifthReferenceValueCumulative;
        let startingSixthReferenceValueCumulative = sixthReferenceValueCumulative;
        let startingSeventhReferenceValueCumulative = seventhReferenceValueCumulative;
        let startingAbsoluteCumulative = absoluteCumulative;
        let startingSecondAbsoluteCumulative = secondAbsoluteCumulative;
        let startingThirdAbsoluteCumulative = thirdAbsoluteCumulative;
        let startingFourthAbsoluteCumulative = fourthAbsoluteCumulative;
        let startingFifthAbsoluteCumulative = fifthAbsoluteCumulative;
        let startingSixthAbsoluteCumulative = sixthAbsoluteCumulative;
        let startingSeventhAbsoluteCumulative = seventhAbsoluteCumulative;

        if (this.isGrandTotal) {
            return this.grandTotalDataPoint.calculateGrandTotalCumulative();
        }
        if (this.hasChildHierarchies) {
            for (let i = 0; i < this.chartHierarchies.length; i++) {
                let hierarchy = this.chartHierarchies[i];
                let cmmo = hierarchy.calculateCumulative(valueCumulative, referenceValueCumulative, secondReferenceValueCumulative, thirdReferenceValueCumulative, fourthReferenceValueCumulative, fifthReferenceValueCumulative, sixthReferenceValueCumulative, seventhReferenceValueCumulative, absoluteCumulative, secondAbsoluteCumulative, thirdAbsoluteCumulative, fourthAbsoluteCumulative, fifthAbsoluteCumulative, sixthAbsoluteCumulative, seventhAbsoluteCumulative, locale, percentageFormat, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                calculations.calculateExtremes(cmmo.value, valueResult);
                calculations.calculateExtremes(cmmo.referenceValue, referenceValueResult);
                calculations.calculateExtremes(cmmo.secondReferenceValue, secondReferenceValueResult);
                calculations.calculateExtremes(cmmo.thirdReferenceValue, thirdReferenceValueResult);
                calculations.calculateExtremes(cmmo.fourthReferenceValue, fourthReferenceValueResult);
                calculations.calculateExtremes(cmmo.fifthReferenceValue, fifthReferenceValueResult);
                calculations.calculateExtremes(cmmo.sixthReferenceValue, sixthReferenceValueResult);
                calculations.calculateExtremes(cmmo.seventhReferenceValue, seventhReferenceValueResult);
                calculations.calculateExtremes(cmmo.absolute, absoluteResult);
                calculations.calculateExtremes(cmmo.secondAbsolute, secondAbsoluteResult);
                calculations.calculateExtremes(cmmo.thirdAbsolute, thirdAbsoluteResult);
                calculations.calculateExtremes(cmmo.fourthAbsolute, fourthAbsoluteResult);
                calculations.calculateExtremes(cmmo.fifthAbsolute, fifthAbsoluteResult);
                calculations.calculateExtremes(cmmo.sixthAbsolute, sixthAbsoluteResult);
                calculations.calculateExtremes(cmmo.seventhAbsolute, seventhAbsoluteResult);
                valueCumulative = hierarchy.valueCumulative;
                referenceValueCumulative = hierarchy.referenceValueCumulative;
                secondReferenceValueCumulative = hierarchy.secondReferenceValueCumulative;
                thirdReferenceValueCumulative = hierarchy.thirdReferenceValueCumulative;
                fourthReferenceValueCumulative = hierarchy.fourthReferenceValueCumulative;
                fifthReferenceValueCumulative = hierarchy.fifthReferenceValueCumulative;
                sixthReferenceValueCumulative = hierarchy.sixthReferenceValueCumulative;
                seventhReferenceValueCumulative = hierarchy.seventhReferenceValueCumulative;
                absoluteCumulative = hierarchy.absoluteCumulative;
                secondAbsoluteCumulative = hierarchy.secondAbsoluteCumulative;
                thirdAbsoluteCumulative = hierarchy.thirdAbsoluteCumulative;
                fourthAbsoluteCumulative = hierarchy.fourthAbsoluteCumulative;
                fifthAbsoluteCumulative = hierarchy.fifthAbsoluteCumulative;
                sixthAbsoluteCumulative = hierarchy.sixthAbsoluteCumulative;
                seventhAbsoluteCumulative = hierarchy.seventhAbsoluteCumulative;
                this.updateExtremes(valueResult, referenceValueResult, secondReferenceValueResult, thirdReferenceValueResult, fourthReferenceValueResult, fifthReferenceValueResult, sixthReferenceValueResult, seventhReferenceValueResult,
                    absoluteResult, secondAbsoluteResult, thirdAbsoluteResult, fourthAbsoluteResult, fifthAbsoluteResult, sixthAbsoluteResult, seventhAbsoluteResult);
            }
        }
        else {
            for (let i = 0; i < this.addOtherDataPointIfNeeded(this._dataPoints).length; i++) {
                let dataPoint = this.addOtherDataPointIfNeeded(this._dataPoints)[i];
                dataPoint.setCumulative(valueCumulative, referenceValueCumulative, secondReferenceValueCumulative, thirdReferenceValueCumulative, fourthReferenceValueCumulative, fifthReferenceValueCumulative, sixthReferenceValueCumulative, seventhReferenceValueCumulative,
                    absoluteCumulative, secondAbsoluteCumulative, thirdAbsoluteCumulative, fourthAbsoluteCumulative, fifthAbsoluteCumulative, sixthAbsoluteCumulative, seventhAbsoluteCumulative);
                valueCumulative = this.calculateCumulativeExtreme(valueResult, dataPoint, DataProperty.Value, valueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                referenceValueCumulative = this.calculateCumulativeExtreme(referenceValueResult, dataPoint, DataProperty.ReferenceValue, referenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                secondReferenceValueCumulative = this.calculateCumulativeExtreme(secondReferenceValueResult, dataPoint, DataProperty.SecondReferenceValue, secondReferenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                thirdReferenceValueCumulative = this.calculateCumulativeExtreme(thirdReferenceValueResult, dataPoint, DataProperty.ThirdReferenceValue, thirdReferenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                fourthReferenceValueCumulative = this.calculateCumulativeExtreme(fourthReferenceValueResult, dataPoint, DataProperty.FourthReferenceValue, fourthReferenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                fifthReferenceValueCumulative = this.calculateCumulativeExtreme(fifthReferenceValueResult, dataPoint, DataProperty.FifthReferenceValue, fifthReferenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                sixthReferenceValueCumulative = this.calculateCumulativeExtreme(sixthReferenceValueResult, dataPoint, DataProperty.SixthReferenceValue, sixthReferenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                seventhReferenceValueCumulative = this.calculateCumulativeExtreme(seventhReferenceValueResult, dataPoint, DataProperty.SeventhReferenceValue, seventhReferenceValueCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                absoluteCumulative = this.calculateCumulativeExtreme(absoluteResult, dataPoint, DataProperty.AbsoluteDifference, absoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                secondAbsoluteCumulative = this.calculateCumulativeExtreme(secondAbsoluteResult, dataPoint, DataProperty.SecondAbsoluteDifference, secondAbsoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                thirdAbsoluteCumulative = this.calculateCumulativeExtreme(thirdAbsoluteResult, dataPoint, DataProperty.ThirdAbsoluteDifference, thirdAbsoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                fourthAbsoluteCumulative = this.calculateCumulativeExtreme(fourthAbsoluteResult, dataPoint, DataProperty.FourthAbsoluteDifference, fourthAbsoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                fifthAbsoluteCumulative = this.calculateCumulativeExtreme(fifthAbsoluteResult, dataPoint, DataProperty.FifthAbsoluteDifference, fifthAbsoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                sixthAbsoluteCumulative = this.calculateCumulativeExtreme(sixthAbsoluteResult, dataPoint, DataProperty.SixthAbsoluteDifference, sixthAbsoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
                seventhAbsoluteCumulative = this.calculateCumulativeExtreme(seventhAbsoluteResult, dataPoint, DataProperty.SeventhAbsoluteDifference, seventhAbsoluteCumulative, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel);
            }
            if (this.settings.valueChart === ValueChart.CalculationWaterfall && this.totalDataPoint && this.totalDataPoint.isResult) {
                if (this.settings.hasInvertOrResultOrSkip() && this.settings.showTotals !== ShowTotals.AboveHideValues) {
                    if (this.oneDataPointWithSameCategoryAsTotal()) {
                        this.totalDataPoint.value = this.addOtherDataPointIfNeeded(this._dataPoints)[0].value;
                        this.totalDataPoint.referenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].referenceValue;
                        this.totalDataPoint.secondReferenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].secondReferenceValue;
                        this.totalDataPoint.thirdReferenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].thirdReferenceValue;
                        this.totalDataPoint.fourthReferenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].fourthReferenceValue;
                        this.totalDataPoint.fifthReferenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].fifthReferenceValue;
                        this.totalDataPoint.sixthReferenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].sixthReferenceValue;
                        this.totalDataPoint.seventhReferenceValue = this.addOtherDataPointIfNeeded(this._dataPoints)[0].seventhReferenceValue;
                    }
                    else {
                        this.totalDataPoint.value = valueCumulative;
                        this.totalDataPoint.referenceValue = referenceValueCumulative;
                        this.totalDataPoint.secondReferenceValue = secondReferenceValueCumulative;
                        this.totalDataPoint.thirdReferenceValue = thirdReferenceValueCumulative;
                        this.totalDataPoint.fourthReferenceValue = fourthReferenceValueCumulative;
                        this.totalDataPoint.fifthReferenceValue = fifthReferenceValueCumulative;
                        this.totalDataPoint.sixthReferenceValue = sixthReferenceValueCumulative;
                        this.totalDataPoint.seventhReferenceValue = seventhReferenceValueCumulative;
                    }
                }
                this.totalDataPoint.calculate(locale, percentageFormat);
                this.updateExtremes(valueResult, referenceValueResult, secondReferenceValueResult, thirdReferenceValueResult, fourthReferenceValueResult, fifthReferenceValueResult, sixthReferenceValueResult, seventhReferenceValueResult,
                    absoluteResult, secondAbsoluteResult, thirdAbsoluteResult, fourthAbsoluteResult, fifthAbsoluteResult, sixthAbsoluteResult, seventhAbsoluteResult);
            }
        }
        if (this.isHierarchical) {
            if (this.totalDataPoint.isResult) {
                this.totalDataPoint.valueCumulative = 0;
                this.totalDataPoint.referenceValueCumulative = 0;
                this.totalDataPoint.secondReferenceValueCumulative = 0;
                this.totalDataPoint.thirdReferenceValueCumulative = 0;
                this.totalDataPoint.fourthReferenceValueCumulative = 0;
                this.totalDataPoint.fifthReferenceValueCumulative = 0;
                this.totalDataPoint.sixthReferenceValueCumulative = 0;
                this.totalDataPoint.seventhReferenceValueCumulative = 0;
                this.totalDataPoint.absoluteCumulative = 0;
                this.totalDataPoint.secondAbsoluteCumulative = 0;
                this.totalDataPoint.thirdAbsoluteCumulative = 0;
                this.totalDataPoint.fourthAbsoluteCumulative = 0;
                this.totalDataPoint.fifthAbsoluteCumulative = 0;
                this.totalDataPoint.sixthAbsoluteCumulative = 0;
                this.totalDataPoint.seventhAbsoluteCumulative = 0;
            }
            else {
                this.totalDataPoint.valueCumulative = this.getTotalPointCumulative(startingValueCumulative, valueCumulative, DataProperty.Value);
                this.totalDataPoint.referenceValueCumulative = this.getTotalPointCumulative(startingReferenceValueCumulative, referenceValueCumulative, DataProperty.ReferenceValue);
                this.totalDataPoint.secondReferenceValueCumulative = this.getTotalPointCumulative(startingSecondReferenceValueCumulative, secondReferenceValueCumulative, DataProperty.SecondReferenceValue);
                this.totalDataPoint.thirdReferenceValueCumulative = this.getTotalPointCumulative(startingThirdReferenceValueCumulative, thirdReferenceValueCumulative, DataProperty.ThirdReferenceValue);
                this.totalDataPoint.fourthReferenceValueCumulative = this.getTotalPointCumulative(startingFourthReferenceValueCumulative, fourthReferenceValueCumulative, DataProperty.FourthReferenceValue);
                this.totalDataPoint.fifthReferenceValueCumulative = this.getTotalPointCumulative(startingFifthReferenceValueCumulative, fifthReferenceValueCumulative, DataProperty.FifthReferenceValue);
                this.totalDataPoint.sixthReferenceValueCumulative = this.getTotalPointCumulative(startingSixthReferenceValueCumulative, sixthReferenceValueCumulative, DataProperty.SixthReferenceValue);
                this.totalDataPoint.seventhReferenceValueCumulative = this.getTotalPointCumulative(startingSeventhReferenceValueCumulative, seventhReferenceValueCumulative, DataProperty.SeventhReferenceValue);
                this.totalDataPoint.absoluteCumulative = this.getTotalPointCumulative(startingAbsoluteCumulative, absoluteCumulative, DataProperty.AbsoluteDifference);
                this.totalDataPoint.secondAbsoluteCumulative = this.getTotalPointCumulative(startingSecondAbsoluteCumulative, secondAbsoluteCumulative, DataProperty.SecondAbsoluteDifference);
                this.totalDataPoint.thirdAbsoluteCumulative = this.getTotalPointCumulative(startingThirdAbsoluteCumulative, thirdAbsoluteCumulative, DataProperty.ThirdAbsoluteDifference);
                this.totalDataPoint.fourthAbsoluteCumulative = this.getTotalPointCumulative(startingFourthAbsoluteCumulative, fourthAbsoluteCumulative, DataProperty.FourthAbsoluteDifference);
                this.totalDataPoint.fifthAbsoluteCumulative = this.getTotalPointCumulative(startingFifthAbsoluteCumulative, fifthAbsoluteCumulative, DataProperty.FifthAbsoluteDifference);
                this.totalDataPoint.sixthAbsoluteCumulative = this.getTotalPointCumulative(startingSixthAbsoluteCumulative, sixthAbsoluteCumulative, DataProperty.SixthAbsoluteDifference);
                this.totalDataPoint.seventhAbsoluteCumulative = this.getTotalPointCumulative(startingSeventhAbsoluteCumulative, seventhAbsoluteCumulative, DataProperty.SeventhAbsoluteDifference);
            }
        }
        this.valueCumulative = valueCumulative;
        this.referenceValueCumulative = referenceValueCumulative;
        this.secondReferenceValueCumulative = secondReferenceValueCumulative;
        this.thirdReferenceValueCumulative = thirdReferenceValueCumulative;
        this.fourthReferenceValueCumulative = fourthReferenceValueCumulative;
        this.fifthReferenceValueCumulative = fifthReferenceValueCumulative;
        this.sixthReferenceValueCumulative = sixthReferenceValueCumulative;
        this.seventhReferenceValueCumulative = seventhReferenceValueCumulative;
        this.absoluteCumulative = absoluteCumulative;
        this.secondAbsoluteCumulative = secondAbsoluteCumulative;
        this.thirdAbsoluteCumulative = thirdAbsoluteCumulative;
        this.fourthAbsoluteCumulative = fourthAbsoluteCumulative;
        this.fifthAbsoluteCumulative = fifthAbsoluteCumulative;
        this.sixthAbsoluteCumulative = sixthAbsoluteCumulative;
        this.seventhAbsoluteCumulative = seventhAbsoluteCumulative;
        return {
            value: valueResult,
            referenceValue: referenceValueResult,
            secondReferenceValue: secondReferenceValueResult,
            thirdReferenceValue: thirdReferenceValueResult,
            fourthReferenceValue: fourthReferenceValueResult,
            fifthReferenceValue: fifthReferenceValueResult,
            sixthReferenceValue: sixthReferenceValueResult,
            seventhReferenceValue: seventhReferenceValueResult,
            absolute: absoluteResult,
            secondAbsolute: secondAbsoluteResult,
            thirdAbsolute: thirdAbsoluteResult,
            fourthAbsolute: fourthAbsoluteResult,
            fifthAbsolute: fifthAbsoluteResult,
            sixthAbsolute: sixthAbsoluteResult,
            seventhAbsolute: seventhAbsoluteResult,
        };
    }

    private updateExtremes(valueResult: Extreme, referenceValueResult: Extreme, secondReferenceValueResult: Extreme, thirdReferenceValueResult: Extreme, fourthReferenceValueResult: Extreme, fifthReferenceValueResult: Extreme, sixthReferenceValueResult: Extreme, seventhReferenceValueResult: Extreme,
        absoluteResult: Extreme, secondAbsoluteResult: Extreme, thirdAbsoluteResult: Extreme, fourthAbsoluteResult: Extreme, fifthAbsoluteResult: Extreme, sixthAbsoluteResult: Extreme, seventhAbsoluteResult: Extreme) {
        let totalDataPointCmmo = this.getTotalDataPointCmmo();
        calculations.calculateExtremes(totalDataPointCmmo.value, valueResult);
        calculations.calculateExtremes(totalDataPointCmmo.referenceValue, referenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.secondReferenceValue, secondReferenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.thirdReferenceValue, thirdReferenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.fourthReferenceValue, fourthReferenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.fifthReferenceValue, fifthReferenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.sixthReferenceValue, sixthReferenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.seventhReferenceValue, seventhReferenceValueResult);
        calculations.calculateExtremes(totalDataPointCmmo.absolute, absoluteResult);
        calculations.calculateExtremes(totalDataPointCmmo.secondAbsolute, secondAbsoluteResult);
        calculations.calculateExtremes(totalDataPointCmmo.thirdAbsolute, thirdAbsoluteResult);
        calculations.calculateExtremes(totalDataPointCmmo.fourthAbsolute, fourthAbsoluteResult);
        calculations.calculateExtremes(totalDataPointCmmo.fifthAbsolute, fifthAbsoluteResult);
        calculations.calculateExtremes(totalDataPointCmmo.sixthAbsolute, sixthAbsoluteResult);
        calculations.calculateExtremes(totalDataPointCmmo.seventhAbsolute, seventhAbsoluteResult);
    }

    private getTotalPointCumulative(startingCumulative: number, currentCumulative: number, dp: DataProperty): number {
        let result = startingCumulative;
        if (this.totalDataPoint.getValue(dp) < 0 && startingCumulative < currentCumulative) {
            this.totalDataPoint.invertedSubtotalCalculation[dp] = true;
            result = currentCumulative;
        }
        else if (this.totalDataPoint.getValue(dp) > 0 && startingCumulative > currentCumulative) {
            this.totalDataPoint.invertedSubtotalCalculation[dp] = true;
            result = currentCumulative;
        }
        return result;
    }

    private getTotalDataPointCmmo(): CumulativeMinMaxAndOffsets {
        let t = this.totalDataPoint;
        return {
            value: new Extreme(t.value, t.value, 0, 0),
            referenceValue: new Extreme(t.referenceValue, t.referenceValue, 0, 0),
            secondReferenceValue: new Extreme(t.secondReferenceValue, t.secondReferenceValue, 0, 0),
            thirdReferenceValue: new Extreme(t.thirdReferenceValue, t.thirdReferenceValue, 0, 0),
            fourthReferenceValue: new Extreme(t.fourthReferenceValue, t.fourthReferenceValue, 0, 0),
            fifthReferenceValue: new Extreme(t.fifthReferenceValue, t.fifthReferenceValue, 0, 0),
            sixthReferenceValue: new Extreme(t.sixthReferenceValue, t.sixthReferenceValue, 0, 0),
            seventhReferenceValue: new Extreme(t.seventhReferenceValue, t.seventhReferenceValue, 0, 0),
            absolute: new Extreme(t.absoluteDifference, t.absoluteDifference, 0, 0),
            secondAbsolute: new Extreme(t.secondAbsoluteDifference, t.secondAbsoluteDifference, 0, 0),
            thirdAbsolute: new Extreme(t.thirdAbsoluteDifference, t.thirdAbsoluteDifference, 0, 0),
            fourthAbsolute: new Extreme(t.fourthAbsoluteDifference, t.fourthAbsoluteDifference, 0, 0),
            fifthAbsolute: new Extreme(t.fifthAbsoluteDifference, t.fifthAbsoluteDifference, 0, 0),
            sixthAbsolute: new Extreme(t.sixthAbsoluteDifference, t.sixthAbsoluteDifference, 0, 0),
            seventhAbsolute: new Extreme(t.seventhAbsoluteDifference, t.seventhAbsoluteDifference, 0, 0),
        };
    }

    private calculateCumulativeExtreme(extreme: Extreme, d: DataPoint, dataProperty: DataProperty, cumulative: number, maximumExpandeHierarchyLevel: number, maximumExpandedGroupLevel: number): number {
        if (this.settings.showDataLabels) {
            extreme.minLabelOffset = d.getCumulativeLabelOffset(extreme.minLabelOffset, true, dataProperty, maximumExpandeHierarchyLevel, maximumExpandedGroupLevel);
            extreme.maxLabelOffset = d.getCumulativeLabelOffset(extreme.maxLabelOffset, false, dataProperty, maximumExpandeHierarchyLevel, maximumExpandedGroupLevel);
        }
        let startCumulative = cumulative;
        if (d.isFlatResult()) {
            let result = d.getCumulative(dataProperty) + d.getValue(dataProperty);
            if (result < extreme.min) {
                extreme.min = result;
            }
            if (result > extreme.max) {
                extreme.max = result;
            }
            return result;
        }
        if (d.getValue(dataProperty) && !d.isResult) {
            cumulative += d.getValue(dataProperty) * ((d.isInverted || d.isInvertedTotal) ? -1 : 1);
        }
        if (cumulative < extreme.min) {
            extreme.min = cumulative;
        }
        if (cumulative > extreme.max) {
            extreme.max = cumulative;
        }
        if (d.isSkipped) {
            return startCumulative;
        }
        return cumulative;
    }

    public calculateCumulativeLabelOffset(xScale: d3.ScaleLinear<number, number>, width: number, locale: string, dataProperty: DataProperty): MinMax {
        if (this.hasChildHierarchies) {
            return this.chartHierarchies.reduce((result, current) => {
                let mm = current.calculateCumulativeLabelOffset(xScale, width, locale, dataProperty);
                return { min: Math.max(result.min, mm.min), max: Math.max(result.max, mm.max) };
            }, { min: 0, max: 0 });
        }
        else {
            return this.addOtherDataPointIfNeeded(this._dataPoints).reduce((result, dataPoint) => {
                let mm = dataPoint.calculateCumulativeLabelOffset(xScale, width, locale, dataProperty);
                return { min: Math.max(result.min, mm.min), max: Math.max(result.max, mm.max) };
            }, { min: 0, max: 0 });
        }
    }

    public setAutomaticInvertsAndResults() {
        if (this.isGrandTotal) {
            return;
        }
        if (this.areAllPointsInverted() && !this.settings.isUserOverriden(this.totalDataPoint) && !this.totalDataPoint.isInverted && !this.totalDataPoint.isResult) {
            this.totalDataPoint.isInverted = true;
            this.settings.hasAutoInverts = true;
        }
        else if (this.areSomePointsInvertedAndSomeNot() && !this.settings.isUserOverriden(this.totalDataPoint) && !this.totalDataPoint.isInverted && !this.totalDataPoint.isResult) {
            this.totalDataPoint.isResult = true;
            this.settings.hasAutoResults = true;
        }
    }

    private areAllPointsInverted(): boolean {
        if (this.hasChildHierarchies) {
            for (let i = 0; i < this.chartHierarchies.length; i++) {
                let allInverted = this.chartHierarchies[i].areAllPointsInverted();
                if (!allInverted) {
                    return false;
                }
            }
            return true;
        }
        return this.addOtherDataPointIfNeeded(this._dataPoints).every(d => d.isInverted) && this._dataPoints.length > 0;
    }

    private areSomePointsInvertedAndSomeNot(): boolean {
        return !this.areAllPointsInverted() && this.addOtherDataPointIfNeeded(this._dataPoints).some(d => d.isInverted);
    }

    public shouldInvertContributions(): boolean {
        if (!this._dataPoints || this._dataPoints.length === 0) {
            return false;
        }
        let allDataPoints = this.addOtherDataPointIfNeeded(this._dataPoints);
        let invertedDataPoints = allDataPoints.filter(d => d.isInverted);
        return invertedDataPoints.length > 0 && invertedDataPoints.length < allDataPoints.length;
    }

    // public getChildrenSelectionIds(): ISelectionId[] {
    //     let children = this.getChildren(true);
    //     return children.filter(d => d.selectionId).map(d => d.selectionId);
    // }

    public isCollapsed() {
        return this.totalDataPoint && this.totalDataPoint.isCollapsed;
    }

    public getCategoryWidths(prefixWidth: number, maximumExpandedHierarchyLevel: number, maximumExpandedGroupLevel): number[] {
        let categoryWidths = [];
        if (this.otherDataPoint && this.shouldDrawOther) {
            let other = this.otherDataPoint;
            let width = Math.min(drawing.measureTextHeight(this.settings.topNOtherLabel, this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL), 24);
            categoryWidths.push(width - 8 + other.getLeftIndent(prefixWidth) + drawing.measureTextWidth(other.category, this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL));
        }
        if (this.hasChildHierarchies) {
            let total = this.totalDataPoint;
            categoryWidths.push(total.getLeftIndent(prefixWidth) + drawing.measureTextWidth(total.category, this.settings.labelFontSize, this.settings.labelFontFamily, total.getFontWeight(), NORMAL));
            if (!this.isCollapsed()) {
                this.chartHierarchies.forEach(hierarchy => {
                    categoryWidths = categoryWidths.concat(hierarchy.getCategoryWidths(prefixWidth, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel));
                });
            }
        }
        else {
            this.dataPoints().forEach(dataPoint => {
                categoryWidths.push(dataPoint.getLeftIndent(prefixWidth) + drawing.measureTextWidth(dataPoint.category, this.settings.labelFontSize, this.settings.labelFontFamily, dataPoint.getFontWeight(), NORMAL));
            });
        }
        return categoryWidths;
    }

    public getCategoryWidthsByLevel(prefixWidth: number, maximumExpandedHierarchyLevel: number, maximumExpandedGroupLevel: number): LevelCategoryWidth[] {
        let categoryWidths: LevelCategoryWidth[] = [];
        if (this.otherDataPoint && this.shouldDrawOther) {
            let other = this.otherDataPoint;
            let width = Math.min(drawing.measureTextHeight(this.settings.topNOtherLabel, this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL), 24);
            categoryWidths.push({
                level: other.level,
                categoryWidth: width - 8 + other.getLeftIndent(prefixWidth) + drawing.measureTextWidth(other.category, this.settings.labelFontSize, this.settings.labelFontFamily, BOLD, NORMAL)
            });
        }
        if (this.hasChildHierarchies) {
            let total = this.totalDataPoint;
            categoryWidths.push({
                level: total.level,
                categoryWidth: total.getLeftIndent(prefixWidth) + drawing.measureTextWidth(total.category, this.settings.labelFontSize, this.settings.labelFontFamily, total.getFontWeight(), NORMAL),
            });
            if (!this.isCollapsed()) {
                this.chartHierarchies.forEach(hierarchy => {
                    categoryWidths = categoryWidths.concat(hierarchy.getCategoryWidthsByLevel(prefixWidth, maximumExpandedHierarchyLevel, maximumExpandedGroupLevel));
                });
            }
        }
        else {
            this.dataPoints().forEach(dataPoint => {
                categoryWidths.push({
                    level: dataPoint.level,
                    categoryWidth: dataPoint.getLeftIndent(prefixWidth) + drawing.measureTextWidth(dataPoint.category, this.settings.labelFontSize, this.settings.labelFontFamily, dataPoint.getFontWeight(), NORMAL),
                });
            });
        }
        return categoryWidths;
    }

    public getAllHierarchiesByLevel(level: number): ChartHierarchy[] {
        if (this.otherDataPoint && !this._dataPoints.length) {
            return [];
        }
        if (this.level === level) {
            return [this];
        }
        else if (this.hasChildHierarchies) {
            return this.chartHierarchies.reduce((result, h) => result.concat(h.getAllHierarchiesByLevel(level)), []);
        }
        return [];
    }

    public getChartHeights(categoryHeight: number): number[] {
        if (this.hasChildHierarchies) {
            this.chartHierarchies.reduce((result: number[], hierarchy: ChartHierarchy): number[] => {
                return result.concat(hierarchy.getChartHeights(categoryHeight));
            }, []);
        }
        else {
            return [this.dataPoints().length];
        }
    }

    public getChildren(alsoSinglePointHierarchies: boolean): DataPoint[] {
        if (this.hasChildHierarchies) {
            return this.chartHierarchies.reduce((result: DataPoint[], h: ChartHierarchy) => result.concat(h.getChildren(alsoSinglePointHierarchies)), [this.totalDataPoint]);
        }
        else {
            return this.dataPoints(true, alsoSinglePointHierarchies);
        }
    }

    public getAllDataPoints(): DataPoint[] {
        if (this.hasChildHierarchies) {
            return this.chartHierarchies.reduce((result, hierarchy) => {
                return result.concat(hierarchy.getAllDataPoints());
            }, [this.totalDataPoint]);
        }
        else {
            return this.dataPoints(false, true);
        }
    }

    public getParents(): IHierarchical[] {
        let current = <IHierarchical>this;
        let parents = [];
        while (current.parent) {
            parents.push(current.parent);
            current = current.parent;
        }
        return parents;
    }

    public getHierarchyParents(): IHierarchical[] {
        let current = <IHierarchical>this;
        let parents = [];
        while (current.parent && current.parent instanceof ChartHierarchy) {
            parents.push(current.parent);
            current = current.parent;
        }
        return parents;
    }

    public markSelectedPoints(selectionIds: string[]): void {
        let otherSelected = false;
        if (this.hasChildHierarchies) {
            if (this.isSelected(selectionIds)) {
                this.chartHierarchies.forEach(h => h.markAllPointsAsSelected());
                this.markAllPointsAsSelected();
                otherSelected = this.otherDataPoint ? true : false;
                let parent = <IHierarchical>this;
                while (parent && (<any>parent).totalDataPoint) {
                    (<any>parent).totalDataPoint.isSelected = true;
                    parent = parent.parent;
                }
            }
            else {
                this.chartHierarchies.forEach(h => h.markSelectedPoints(selectionIds));
            }
        }
        else {
            if (this.isSelected(selectionIds)) {
                this.markAllPointsAsSelected();
                otherSelected = this.otherDataPoint ? true : false;
            }
            else {
                let anyPointSelected = false;
                for (let i = 0; i < this.dataPoints().length; i++) {
                    let d = this.dataPoints()[i];
                    d.isSelected = this.isDataPointInSelectionIds(d, selectionIds) || (d.isOther() && d.associatedDataPoints.every(dp => this.isDataPointInSelectionIds(dp, selectionIds)));
                    if (d.isSelected && this.totalDataPoint) {
                        anyPointSelected = true;
                    }
                }
                if (anyPointSelected) {
                    let parent = <IHierarchical>this;
                    while (parent && (<any>parent).totalDataPoint) {
                        (<any>parent).totalDataPoint.isSelected = true;
                        parent = parent.parent;
                    }
                }
            }
        }
        if (this.otherDataPoint && (otherSelected || this.otherDataPoint.associatedDataPoints.every(dp => this.isDataPointInSelectionIds(dp, selectionIds)))) {
            this.otherDataPoint.isSelected = true;
        } else {
            if (this.otherDataPoint) {
                this.otherDataPoint.isSelected = false;
            }
        }
    }

    public isSelected(selectionIds: string[]): boolean {
        if (this.settings.plottingHorizontally()) {
            return this.isTotalDataPointSelected(selectionIds) || (this.totalDataPoint && this.isWholeRowSelected(selectionIds));
        }
        else {
            return this.isTotalDataPointSelected(selectionIds);
        }
    }

    private isTotalDataPointSelected(selectionIds: string[]): boolean {
        return false; //selectionIds.some(id => this.totalDataPoint && this.totalDataPoint.selectionId && this.totalDataPoint.selectionId === id);
    }

    public isWholeRowSelected(selectionIds: string[]): boolean {
        return selectionIds.some(id => id === this.fullCategory);
    }

    public markAllPointsAsSelected(): void {
        this.dataPoints().forEach(d => d.isSelected = true);
    }

    private isDataPointInSelectionIds(d: DataPoint, selectionIds: string[]): boolean {
        return selectionIds.some((currentSelectionId: string) => {
            return d.fullCategory && currentSelectionId && d.fullCategory === currentSelectionId;
        });
    }

    public removeDataPointsByCategory(categoriesToDelete: Map<string, boolean>) {
        if (this.hasChildHierarchies) {
            this.chartHierarchies.forEach(ch => {
                ch.removeDataPointsByCategory(categoriesToDelete);
            });
        } else {
            this._dataPoints = this._dataPoints.filter(dp => !categoriesToDelete.get(dp.fullCategory));
        }
    }

    public getPreviousHierarchy(): ChartHierarchy {
        let parent = this.parent;
        if (!parent) {
            return null;
        }
        let previousHierarchy = null
        for (let index = 0; index < parent.chartHierarchies.length; index++) {
            if (parent.chartHierarchies[index] === this) {
                return previousHierarchy;
            }
            previousHierarchy = parent.chartHierarchies[index];
        }
        return null;
    }

    public getNextHierarchy(): ChartHierarchy {
        let parent = this.parent;
        if (!parent) {
            return null;
        }
        for (let index = 0; index < parent.chartHierarchies.length; index++) {
            if (parent.chartHierarchies[index] === this && index + 1 < parent.chartHierarchies.length) {
                return parent.chartHierarchies[index + 1];
            }
        }
        return null;
    }

    public getHierarchies(): ChartHierarchy[] {
        if (this.totalDataPoint) {
            return this.chartHierarchies;
        }
        return [];
    }

    removeFromDataPoints(dataPoint: DataPoint) {
        this._dataPoints = this._dataPoints.filter(dp => dp.fullCategory !== dataPoint.fullCategory);
    }

    removeFromParent(dataPoint: DataPoint) {
        this.parent.chartHierarchies = this.parent.chartHierarchies.filter(ch => ch.totalDataPoint !== dataPoint);
    }
}