import { licensing } from "@zebrabi/licensing/Licensing";
import * as d3 from "d3";
import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import { A, BLOCK, CLICK, DISPLAY, DIV, NONE, P } from "../../library/constants";
import { CurrentUserInfo } from "packages/licensing/components/UserInfo";
import { BACKGROUND_COLOR } from "../../library/constants";
import { KNOWLEDGE_BASE_URL, ZEBRA_URL, START_TRIAL_URL, UNLOCK_ALL_FEATURES_URL } from "@zebrabi/licensing/constants";
import { LicenseType, ProductType } from "@zebrabi/licensing/licensing.types";
import { licenseToString, openURL } from "@zebrabi/licensing/helpers";
import { signInService } from "@zebrabi/licensing/components/signin/MSFTSignIn";
import BaseSwitcherWithHeaderOld from "./BaseSwitcherWithHeaderOld";
import jwtDecode from "jwt-decode";

class AccountSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = "AccountSwitcher";

    toolbarOptions: ToolbarOptions = {
        actions: [],
        elementName: "Account",
        icon: "user-icon-base64",
        type: "button"
    };

    private buyButton: d3.Selection<HTMLDivElement, any, any, any>;
    private expiryElement: d3.Selection<HTMLDivElement, any, any, any>;
    private licenseTypeElement: d3.Selection<HTMLDivElement, any, any, any>;
    private nameElement: d3.Selection<HTMLDivElement, any, any, any>;
    private emailElement: d3.Selection<HTMLDivElement, any, any, any>;
    private initialsElement: d3.Selection<HTMLDivElement, any, any, any>;
    private signInBlock: d3.Selection<HTMLDivElement, any, any, any>;
    private usernameBlock: d3.Selection<HTMLDivElement, any, any, any>;
    private licenseBlock: d3.Selection<HTMLDivElement, any, any, any>;
    private signOutBlock: d3.Selection<HTMLDivElement, any, any, any>;

    public getClassName(): string {
        return AccountSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
        this.updateMenuItems();
    }

    update(message: Map<string, any>): void {
        if (message.has("userinfo") && this.usernameBlock) {
            this.updateMenuItems();
        }
    }

    updateMenuItems() {
        const accessToken = signInService.getAccessToken();

        if (!accessToken) {
            return;
        }

        const accessTokenData: { name: string, preferred_username: string; } = jwtDecode(accessToken);
        const currentUserInfo: CurrentUserInfo = licensing.getCurrentUser();

        if (!signInService.isSignedIn()) {
            this.usernameBlock.style(DISPLAY, NONE);
            this.licenseBlock.style(DISPLAY, NONE);
            this.signInBlock.style(DISPLAY, BLOCK);
            this.signOutBlock.style(DISPLAY, NONE);
        } else {
            this.signInBlock.style(DISPLAY, NONE);
            this.signOutBlock.style(DISPLAY, BLOCK);
            this.usernameBlock.style(DISPLAY, BLOCK);
            this.licenseBlock.style(DISPLAY, BLOCK);

            const license = currentUserInfo?.getLicense();
            const licenseTypeText = licenseToString(license);

            this.initialsElement.text(this.getInitialsFromString(accessTokenData.name));
            this.nameElement.text(accessTokenData.name);
            this.emailElement.text(accessTokenData.preferred_username);
            this.licenseTypeElement.text(licenseTypeText);

            if (license?.hasLicense) {
                if (license.licenseType === LicenseType.Trial) {
                    this.buyButton.style(DISPLAY, BLOCK);
                    this.expiryElement.style(DISPLAY, BLOCK);
                    const daysLeft = currentUserInfo.getLicense()?.getDaysLeft();
                    this.expiryElement.style(BACKGROUND_COLOR, "#D83B01");
                    this.expiryElement.text(daysLeft === 1 ? `1 day left` : `${daysLeft} days left`);
                } else {
                    this.buyButton.style(DISPLAY, NONE);
                    this.expiryElement.style(DISPLAY, BLOCK);
                    const daysLeft = currentUserInfo.getLicense()?.getDaysLeft();
                    if (daysLeft < 30) {
                        this.expiryElement.style(BACKGROUND_COLOR, "#D83B01");
                        this.expiryElement.text(daysLeft === 1 ? `1 day left` : `${daysLeft} days left`);
                    } else {
                        this.expiryElement.style(BACKGROUND_COLOR, "#2AC47A");
                        this.expiryElement.text(`Renewal on ${currentUserInfo.getValidTo().toLocaleDateString("en-us", { month: "short", day: "numeric", year: "numeric" })}`);
                    }
                }
            } else {
                this.expiryElement?.style(DISPLAY, NONE);
            }
        }
    }

    createMenuItems(switcherMenuContainer: HTMLElement, active: string = "") {
        super.createMenuItems(switcherMenuContainer, active);
        const body = switcherMenuContainer.querySelector(".body");
        // sidebar content appends to switcherMenuContainer

        const userInfoDiv = document.createElement(DIV);
        const userInfoDivSelection = d3.select(userInfoDiv).classed("user-div", true);
        body.appendChild(userInfoDiv);

        this.signInBlock = userInfoDivSelection.append(DIV).classed("inner-user-block", true);
        this.signInBlock.append(DIV).classed("sign-in-text", true).text("Sign in to view account info");
        const signInButton = this.signInBlock.append(DIV).classed("sign-in", true).text("Sign in");
        signInButton.on("click", async () => {
            await signInService.signIn();
        });

        this.usernameBlock = userInfoDivSelection.append(DIV).classed("inner-user-block", true);
        const circleElement = this.usernameBlock.append(DIV).classed("user-circle", true);
        this.initialsElement = circleElement.append(DIV).classed("initials-text", true);
        const userInfoElement = this.usernameBlock.append(DIV).classed("user-text-info", true);
        this.nameElement = userInfoElement.append(P).classed("username", true);
        this.emailElement = userInfoElement.append(P).classed("email", true);

        this.licenseBlock = userInfoDivSelection.append(DIV).classed("inner-user-block", true);
        this.licenseBlock.append(DIV).classed("license-type-literal", true).text("License type:");
        this.licenseTypeElement = this.licenseBlock.append(DIV).classed("license-type-account-text", true);

        this.expiryElement = this.licenseBlock.append(DIV).classed("license-expiry", true);

        // Create the buy button and set the text depending on the current user license
        const currentUserInfo: CurrentUserInfo = licensing.getCurrentUser();
        const isTrialUser = currentUserInfo?.isTrial();

        if (currentUserInfo?.getLicense()) {
            let buyButtonText = isTrialUser ? "Upgrade" : "Start Free Trial";
            this.buyButton = this.licenseBlock.append(DIV).classed("license-buy-btn", true).text(buyButtonText).on("click", () => {
                const buyUrl = (isTrialUser ? UNLOCK_ALL_FEATURES_URL : START_TRIAL_URL)
                    // eslint-disable-next-line no-undef
                    .replace("$host", Office.context.host === Office.HostType.Excel ? ProductType.Excel : ProductType.PowerPoint)
                    .replace("$visual", process.env.ZBI_VISUAL);
                openURL(buyUrl);
            });
        }

        this.signOutBlock = userInfoDivSelection.append(DIV).classed("inner-user-block", true);
        this.signOutBlock.append(DIV).classed("sign-in-text", true).text("Sign out from the add-in");
        const signOutButton = this.signOutBlock.append(DIV).classed("sign-in", true).text("Sign out");
        signOutButton.on("click", () => {
            signInService.signOut();
        });

        this.updateMenuItems();

        // eslint-disable-next-line no-undef
        const url = ZEBRA_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "tables");
        const footer = userInfoDivSelection.append(DIV).classed("license-footer", true);
        const zebraAnchor = footer.append(A)
            .attr("href", "#")
            .attr("target", "_blank");
        zebraAnchor.on(CLICK, () => {
            (<any>d3.event)?.stopPropagation();
            (<any>d3.event)?.preventDefault();
            openURL(url);
        });
        zebraAnchor.append(DIV).classed("copyright", true);
        zebraAnchor.append(P).text("2023 Zebra BI").classed("help-text", true);

        // eslint-disable-next-line no-undef
        const kbUrl = KNOWLEDGE_BASE_URL.replace("$host", Office.context.host === Office.HostType.Excel ? "excel" : "powerpoint").replace("$visual", "tables");
        const helpAnchor = footer.append(A).attr("href", "#").attr("target", "_blank");
        helpAnchor.on(CLICK, () => {
            (<any>d3.event)?.stopPropagation();
            (<any>d3.event)?.preventDefault();
            openURL(kbUrl);
        });
        helpAnchor.append(DIV).classed("question-mark", true);
        helpAnchor.append(P).text("Help").classed("help-text", true);
    }

    private getInitialsFromString(text: string): string {
        if (!text) {
            return "";
        }
        const arr = text.split(" ");
        if (arr.length > 1) {
            return (arr[0][0] + arr[1][0]).toUpperCase();
        } else {
            return text.slice(0, 2).toUpperCase();
        }
    }

    getScrollPosition(): number {
        return 0;
    }
}

export default AccountSwitcher;
