import { licensing } from "@zebrabi/licensing/Licensing";
import * as d3 from "d3";
import {
    BLACK,
    BLOCK,
    CALCULATIONS,
    CategoryDisplayOptions,
    CategoryRowHeight,
    ChartStyle,
    CLICK,
    DisplayUnits,
    DIV,
    FIELDSET,
    FONT_SIZE_UNIT,
    LEGEND,
    NONE,
    P,
    SEGOE_UI,
    VarianceDisplayType, DataLabelUnitOptions
} from "../../library/constants";
import { ToolbarOptions } from "@zebrabi/global-toolbar-old/interface/ToolbarOption";
import { Visual } from "../../visual";
import { VarianceSettings } from "../../settings/varianceSettings";
import {
    ABSOLUTE, ABSOLUTE_RELATIVE, ACT_ABS_REL, ACTUAL, ACTUAL_ABSOLUTE, ACTUAL_RELATIVE, INTEGRATED, RELATIVE, NegativeValuesFormat
} from "../../definitions";
import { ViewModel } from "../../settings/viewModel";
import Pickr from "@simonwep/pickr";

import { select } from "@zebrabi/global-toolbar-old/components/form/select";
import InputConfig, { InputOption } from "@zebrabi/global-toolbar-old/components/global-toolbar/InputConfig";
import { input } from "@zebrabi/global-toolbar-old/components/form/input";
import { createExportImportSettingsDialog } from "@zebrabi/data-helpers/exportImportSettings";
import { createInvalidLicenseDialog } from "@zebrabi/data-helpers/invalidLicenseDialog";
import { DataView } from "@zebrabi/matrix-data";
import { ORGANIZATION_STYLE_PREFIX, OrganizationStyleSettings } from "@zebrabi/data-helpers/organizationStyles";
import BaseSwitcherWithHeaderOld from "./BaseSwitcherWithHeaderOld";

class SettingsTablesSwitcher extends BaseSwitcherWithHeaderOld {
    static readonly CLASS_NAME = `SettingsTablesSwitcher`;

    // define the title, icon and type of the global toolbar item that will be added
    toolbarOptions: ToolbarOptions = {
        collapsed: true, // if you want the content to directly render to the toolbar row, set this to false
        elementName: "Settings",
        icon: "settings-icon-base64", // icon should be a base64 icon due to powerbi limitations. Additional files are not allowed to be loaded (just the visual.css and visual.js)
        type: "button",
        actions: [], // this is used to auto generate actions mainly used in cards
    };

    constructor(private settings: VarianceSettings, private dataView: DataView, private viewModel: ViewModel) {
        super();
    }

    /**
     * Transpiling will lose the type of the class once minimised, method is used the type is preserved
     */
    public getClassName(): string {
        return SettingsTablesSwitcher.CLASS_NAME;
    }

    buttonAction(action: string, message: string): void {
    }

    update(message: Map<string, any>): void {
        if (message.has("viewer")) {
            if (message.get("viewer") == "true") {
                this.switcherContainer.style.display = NONE;
            } else {
                this.switcherContainer.style.display = BLOCK;
            }
        }

        if (message.has("settings")) {
            this.settings = message.get("settings");
        }

        if (message.has("viewModel")) {
            this.viewModel = message.get("viewModel");
        }

        if (message.has("dataView")) {
            this.dataView = message.get("dataView");
        }

        return;
    }

    createMenuItems(switcherMenuContainer: HTMLElement, active: string = "") {
        super.createMenuItems(switcherMenuContainer, active);
        const body = switcherMenuContainer.querySelector('.body');
        const fieldsForm = d3.select(body).append(DIV).classed("fields-settings-form-2", true);
        let innerForm = fieldsForm.append(DIV).classed("inner-form", true);
        this.createSettingsForm(innerForm);
        this.createImportExportSettingsUI(fieldsForm, innerForm);
    }

    public getCustomColorPicker(pickerID: string, currentColor: string) {
        let colorPicker = Pickr.create({
            el: '#' + pickerID,
            container: '#sandbox-host',
            theme: 'monolith',
            swatches: null,
            appClass: 'fillpickr',
            closeWithKey: 'Escape',
            showAlways: false,
            adjustableNumbers: true,
            default: currentColor,
            useAsButton: true,
            autoReposition: false,
            defaultRepresentation: 'RGBA',
            components: {
                preview: true,
                hue: true,
                opacity: false,
                interaction: {
                    save: true,
                    clear: true,
                    input: true,
                },
            },
        });
        let colorEl = d3.select(`#${pickerID}`);
        let colorInput: HTMLInputElement = <HTMLInputElement>document.getElementById(`${pickerID}Input`);

        colorPicker.on("clear", () => {
            colorEl.style(`background-color`, currentColor);
        }).on("change", (color) => {
            let hexColor = color ? color.toHEXA().toString() : null;
            colorEl.style(`background-color`, hexColor);
            colorInput.value = hexColor;
        }).on("save", () => {
            colorPicker.hide();
            colorInput.dispatchEvent(new Event("change"));
        });

        colorPicker.show();
        // preventing global toolbar to close
        (<any>colorPicker.getRoot()).app.addEventListener(CLICK, (e) => {
            e.stopImmediatePropagation();
        });

        return colorPicker;
    }

    private createImportExportSettingsUI(fieldsForm: d3.Selection<HTMLElement, any, any, any>, innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        const importExportSettingsDiv = document.createElement("div");
        fieldsForm.node().insertBefore(importExportSettingsDiv, innerForm.node());
        importExportSettingsDiv.classList.add("edit-source-data");
        const importExportSettingsDivSelection = d3.select(importExportSettingsDiv);

        const editDataSourceDiv = importExportSettingsDivSelection.append(DIV);
        const importExportSettingsBtn = editDataSourceDiv.append("button")
            .classed("save-theme-btn", true)
            .text("Save theme");

        let settings = Visual.visualSettings;
        const dialogConfirmHandler = (updatedStyle: any) => {
            this.setImportedSettings(settings, updatedStyle);
            settings.persist(true);
            Visual.getInstance().update(settings);
        };

        importExportSettingsBtn.on(CLICK, (event) => {
            if (d3?.event?.detail > 1) {    // prevent double click
                return;
            }
            // Check for valid license
            if (licensing.getCurrentUser()?.getLicense()?.hasLicense) {
                createExportImportSettingsDialog<OrganizationStyleSettings>(this.getExportableSettings(settings), dialogConfirmHandler);
            } else {
                createInvalidLicenseDialog(licensing.getCurrentUser()?.isTrial());
            }

        });
    }


    private getExportableSettings(settings: VarianceSettings): OrganizationStyleSettings {
        return {
            colorScheme: settings.colorScheme,

            labelFontFamily: settings.labelFontFamily,
            labelFontSize: settings.labelFontSize,
            labelFontColor: settings.labelFontColor,

            titleFontFamily: settings.titleFontFamily,
            titleFontSize: settings.titleFontSize,
            titleFontColor: settings.titleFontColor,

            groupTitleFontFamily: settings.groupTitleFontFamily,
            groupTitleFontSize: settings.groupTitleFontSize,
            groupTitleFontColor: settings.groupTitleFontColor,

            varianceDisplayType: settings.varianceDisplayType,

            previousYear: settings.previousYear,
            actual: settings.actual,
            plan: settings.plan,
            forecast: settings.forecast,

            actual_previousYear: settings["actual-previousYear"],
            actual_previousYear_percent: settings["actual-previousYear-percent"],
            actual_plan: settings["actual-plan"],
            actual_plan_percent: settings["actual-plan-percent"],
            actual_forecast: settings["actual-forecast"],
            actual_forecast_percent: settings["actual-forecast-percent"],

            forecast_previousYear: settings["forecast-previousYear"],
            forecast_previousYear_percent: settings["forecast-previousYear-percent"],
            forecast_actual: settings["forecast-actual"],
            forecast_actual_percent: settings["forecast-actual-percent"],
            forecast_plan: settings["forecast-plan"],
            forecast_plan_percent: settings["forecast-plan-percent"],

            plan_previousYear: settings["plan-previousYear"],
            plan_previousYear_percent: settings["plan-previousYear-percent"],
            plan_actual: settings["plan-actual"],
            plan_actual_percent: settings["plan-actual-percent"],
            plan_forecast: settings["plan-forecast"],
            plan_forecast_percent: settings["plan-forecast-percent"],

            previousYear_actual: settings["previousYear-actual"],
            previousYear_actual_percent: settings["previousYear-actual-percent"],
            previousYear_plan: settings["previousYear-plan"],
            previousYear_plan_percent: settings["previousYear-plan-percent"],
            previousYear_forecast: settings["previousYear-forecast"],
            previousYear_forecast_percent: settings["previousYear-forecast-percent"],
        };
    }

    private setImportedSettings(settings: VarianceSettings, importedSettings: OrganizationStyleSettings) {
        settings.chartStyle = ChartStyle.Custom;
        settings.colorScheme = { ...settings.colorScheme, ...importedSettings.colorScheme };
        settings.actual = importedSettings.actual;
        settings.previousYear = importedSettings.previousYear;
        settings.plan = importedSettings.plan;
        settings.forecast = importedSettings.forecast;
        //settings.plan2 = importedSettings.plan2;

        for (let item of CALCULATIONS) {
            const importedSettingKey = item.replace("-", "_");
            if (importedSettings[importedSettingKey]) {
                settings[item] = importedSettings[importedSettingKey];
            }
        }
    }

    private createSettingsForm(innerForm: d3.Selection<HTMLDivElement, any, any, any>) {
        let settings: VarianceSettings = Visual.visualSettings;

        if (!settings) {
            return;
        }

        // CHART SETTINGS
        let chartSettingsFieldset = innerForm.append(FIELDSET);
        chartSettingsFieldset.append(LEGEND).text("Chart settings");
        let chartSettingsDiv = chartSettingsFieldset.append(DIV);

        // Invert variance color
        chartSettingsDiv.node().append(input({
            checked: settings.invert,
            id: "invert-variance-colors-id",
            label: "Invert variance colors",
            name: "invert",
            type: "checkbox",
            value: "true"
        }));

        // Layout
        if ((!this.viewModel?.hasHierarchy || this.viewModel?.HasReferenceColumn) && this.viewModel?.HasReferenceColumn) {
            const validLayoutValues: Map<string, InputOption> = this.getValidChartLayouts();
            chartSettingsDiv.node().append(select({
                label: "Layout",
                name: "chartType",
                id: "layout-select",
                value: settings?.chartType || ACT_ABS_REL,
                options: Array.from(validLayoutValues.values())
            }));
        }

        // Major gridlines
        chartSettingsDiv.node().append(input({
            checked: settings.showMajorGridlines,
            id: "major-gridlines-id",
            label: "Major gridlines",
            name: "showMajorGridlines",
            type: "checkbox",
            value: "true"
        }));

        // Minor gridlines
        chartSettingsDiv.node().append(input({
            checked: settings.showAsTable ? settings.showGridlinesTable : settings.showGridlines,
            id: "minor-gridlines-id",
            label: "Minor gridlines",
            name: settings.showAsTable ? "showGridlinesTable" : "showGridlines",
            type: "checkbox",
            value: "true"
        }));

        // Gridline density
        chartSettingsDiv.node().append(input({
            id: "gridline-density-id",
            label: "Gridline density",
            name: settings.showAsTable ? "gridlineDensityTable" : "gridlineDensity",
            type: "number",
            hidden: settings.showAsTable ? !settings?.showGridlinesTable : !settings?.showGridlines,
            min: 0,
            max: 100,
            value: `${settings.showAsTable ? settings?.gridlineDensityTable : settings?.gridlineDensity}`
        }));

        // Show grand total
        chartSettingsDiv.node().append(input(<InputConfig>{
            id: "grand-total-id",
            label: "Show grand total",
            value: "true",
            type: "checkbox",
            name: "showRowGrandTotal",
            checked: settings?.showRowGrandTotal === true || settings?.showRowGrandTotal === null || settings?.showRowGrandTotal === undefined
        }));

        chartSettingsDiv.node().append(input({
            id: "suppress-large-relative-variance-id",
            label: "Suppress large relative variance",
            value: "true",
            name: "suppressLargeRelativeVariance",
            type: "checkbox",
            checked: settings?.suppressLargeRelativeVariance
        }));

        chartSettingsDiv.node().append(input({
            id: "",
            label: "Suppress large relative variance value",
            value: `${settings?.suppressLargeRelativeVarianceValue}`,
            name: "suppressLargeRelativeVarianceValue",
            hidden: !settings?.suppressLargeRelativeVariance,
            type: "number",
            min: 0,
            max: 10000
        }));

        chartSettingsDiv.node().append(input({
            id: "suppress-zeros-id",
            label: "Suppress zeros",
            value: "true",
            name: "suppressZeros",
            type: "checkbox",
            checked: settings?.suppressZeros
        }));

        chartSettingsDiv.node().append(input({
            id: "suppress-nulls-id",
            label: "Suppress nulls",
            value: "true",
            name: "suppressNulls",
            type: "checkbox",
            checked: settings?.suppressNulls
        }));

        // DATA LABELS
        let dataLabelsFieldset = innerForm.append(FIELDSET);
        dataLabelsFieldset.append(LEGEND).text("Data labels");
        let dataLabelsSettingsDiv = dataLabelsFieldset.append(DIV);


        // Units
        dataLabelsSettingsDiv.node().append(select({
            id: "display-units",
            label: "Units",
            name: "displayUnits",
            value: settings?.displayUnits,
            options: [
                <InputOption>{ title: "Auto", value: DisplayUnits.Auto, default: true },
                <InputOption>{ title: "None", value: DisplayUnits.None },
                <InputOption>{ title: "Thousands", value: DisplayUnits.Thousands },
                <InputOption>{ title: "Millions", value: DisplayUnits.Millions },
                <InputOption>{ title: "Billions", value: DisplayUnits.Billions },
                <InputOption>{ title: "Percentage", value: DisplayUnits.Percent }
            ]
        }));

        if (settings.displayUnits !== DisplayUnits.Auto && settings.displayUnits !== DisplayUnits.None) {
            dataLabelsSettingsDiv.node().append(select({
                id: "show-units-id",
                label: "Show units in",
                name: "showUnits",
                options: [
                    <InputOption>{ title: "None", value: DataLabelUnitOptions.None, default: true },
                    <InputOption>{ title: "Title", value: DataLabelUnitOptions.Title },
                    <InputOption>{ title: "Data labels", value: DataLabelUnitOptions.DataLabels },
                ],
                type: "number",
                value: `${settings.showUnits}`
            }));
        }

        // Decimal places
        dataLabelsSettingsDiv.node().append(input({
            id: "decimal-places",
            label: "Decimal places",
            name: "decimalPlaces",
            value: settings?.decimalPlaces.toString() || "1",
            type: "number",
            min: 0,
            max: 4
        }));

        // Percentage places
        dataLabelsSettingsDiv.node().appendChild(input({
            id: "decimal-places-percent",
            label: "Percentage decimals",
            name: "decimalPlacesPercentage",
            type: "number",
            value: settings?.decimalPlacesPercentage.toString() || "1",
            min: 0,
            max: 4
        }));

        // Show % symbol in numbers
        dataLabelsSettingsDiv.node().append(input({
            id: "percent-numbers-id",
            label: "Show % symbol in numbers",
            name: "showPercentageInLabel",
            value: "true",
            type: "checkbox",
            checked: settings?.showPercentageInLabel === true || settings?.showPercentageInLabel === null || settings?.showPercentageInLabel === undefined
        }));

        // Text size
        dataLabelsSettingsDiv.node().append(input({
            id: "labels-fontsize",
            label: `Text size (${FONT_SIZE_UNIT})`,
            name: "labelFontSize",
            type: "number",
            value: settings?.labelFontSize.toString() || "10",
            min: 8,
            max: 32
        }));

        // Categories row height
        dataLabelsSettingsDiv.node().append(select({
            id: "row-height-selector",
            label: "Row height",
            name: "categoriesRowHeight",
            type: "number",
            value: settings?.categoriesRowHeight.toString() || CategoryRowHeight.Auto.toString(),
            options: [
                <InputOption>{ title: "Auto", value: CategoryRowHeight.Auto, default: true },
                <InputOption>{ title: "Font sized", value: CategoryRowHeight.FontSized },
                <InputOption>{ title: "Font sized x1.1", value: CategoryRowHeight.FontSized_1_1 },
                <InputOption>{ title: "Font sized x1.5", value: CategoryRowHeight.FontSized_1_5 },
                <InputOption>{ title: "Stretch", value: CategoryRowHeight.Stretch },
                <InputOption>{ title: "Fixed", value: CategoryRowHeight.Fixed },
            ]
        }));

        dataLabelsSettingsDiv.node().append(input({
            id: "settings categories-fixedheight",
            label: "Height",
            name: "categoriesHeight",
            value: `${settings?.categoriesHeight}`,
            type: "number",
            hidden: settings?.categoriesRowHeight !== CategoryRowHeight.Fixed,
            min: 0,
            max: 1000
        }));

        // Negative values format
        dataLabelsSettingsDiv.node().append(select({
            id: "negative-values-format",
            label: "Negative values format",
            name: "negativeValuesFormat",
            type: "number",
            value: settings?.negativeValuesFormat ? `${settings.negativeValuesFormat}` : `${NegativeValuesFormat.MinusSign}`,
            options: [
                <InputOption>{ title: "Minus sign | -123.4", value: `${NegativeValuesFormat.MinusSign}`, default: true },
                <InputOption>{ title: "Parenthesis | (123.4)", value: `${NegativeValuesFormat.Parenthesis}` },
            ]
        }));

        // Font family
        const isFontSetInOldVersion = (font: string, substituteFont: string): string => {
            if (settings?.labelFontFamily === font) {
                settings.labelFontFamily = `${font}, ${substituteFont}`;
            }
            return `${font}, ${substituteFont}`;
        };

        const SUBSTITUTE_FONT = "helvetica, arial, sans-serif";

        let labelFontFamilyConfig = {
            id: "font-family",
            label: "Font Family",
            name: "labelFontFamily",
            value: settings?.labelFontFamily ? settings?.labelFontFamily : SEGOE_UI,
            options: [
                <InputOption>{ title: "Calibri", value: isFontSetInOldVersion("Calibri", SUBSTITUTE_FONT), default: true },
                <InputOption>{ title: "Segoe UI", value: SEGOE_UI },
                <InputOption>{ title: "Arial", value: "Arial" },
                <InputOption>{ title: "Consolas", value: isFontSetInOldVersion("Consolas", "monospace") },
                <InputOption>{ title: "Verdana", value: "Verdana" },
            ]
        };

        const findToExistingFontOption = (font: string, options: InputOption[]) => {
            // For comparing the start of font values
            // Example: "'Segoe UI', wf_segoe-ui_normal, ..." -> "Segoe UI"
            const comparableString = (font: string) => font.split(",")[0].replace(new RegExp("'", 'g'), "");

            let fontComparable = comparableString(font);
            for (let i = 0; i < options.length; i++) {
                let optionComparable = comparableString(options[i].value as string);
                if (optionComparable === fontComparable) {
                    return options[i].value as string;
                }
            }
            return null;
        };

        const existingOptionValue = findToExistingFontOption(settings.labelFontFamily, labelFontFamilyConfig.options);
        if (existingOptionValue) {
            // The font setting has a matching option
            // Overwrite the current setting in case the font is outdated
            settings.labelFontFamily = existingOptionValue;
        } else {
            // No matching option, create a new option
            const optionText = settings.labelFontFamily.split(",")[0];
            labelFontFamilyConfig.options.push(
                <InputOption>{ title: optionText, value: settings.labelFontFamily }
            );
        }

        labelFontFamilyConfig.value = settings?.labelFontFamily ? settings?.labelFontFamily : SEGOE_UI;
        dataLabelsSettingsDiv.node().append(select(labelFontFamilyConfig));

        // CATEGORIES
        let categoriesFieldset = innerForm.append(FIELDSET);
        categoriesFieldset.append(LEGEND).text("Categories");
        let categoriesFieldsetDiv = categoriesFieldset.append(DIV);

        // Categories text width
        categoriesFieldsetDiv.node().append(select({
            id: "categories-textwidth-selector",
            label: "Categories text width",
            name: "categoriesDisplayOptions",
            type: "number",
            value: settings?.categoriesDisplayOptions.toString() || CategoryDisplayOptions.Auto.toString(),
            options: [
                <InputOption>{ title: "Auto", value: CategoryDisplayOptions.Auto, default: true },
                <InputOption>{ title: "Full", value: CategoryDisplayOptions.Full },
                <InputOption>{ title: "Fixed", value: CategoryDisplayOptions.FixedWidth },
            ]
        }));

        categoriesFieldsetDiv.node().append(input({
            id: "categories-fixedwidth-input",
            label: "Width",
            name: "categoriesWidth",
            value: `${settings?.categoriesWidth}`,
            type: "number",
            hidden: settings?.categoriesDisplayOptions !== CategoryDisplayOptions.FixedWidth,
            min: 0,
            max: 1000
        }));

        // Freeze categories
        categoriesFieldsetDiv.node().append(input({
            id: "freeze-categories-id",
            label: "Freeze categories",
            name: "freezeCategories",
            type: "checkbox",
            value: "true",
            checked: settings?.freezeCategories
        }));
        // DESIGN
        let designSettingsFieldset = innerForm.append(FIELDSET);
        designSettingsFieldset.append(LEGEND).text("Design");
        let designSettingsDiv = designSettingsFieldset.append(DIV);

        // style
        let orgStyleInputOptions: InputOption[] = [];
        if (Visual.getInstance().availableOrganizationStyles) {
            orgStyleInputOptions = Visual.getInstance().availableOrganizationStyles.map(styleData => {
                return <InputOption>{ title: styleData.name, value: ORGANIZATION_STYLE_PREFIX + styleData.id };
            });
        }

        let designSettingsValue = settings && settings.chartStyle ? settings.chartStyle.toString() : "0";
        if (settings && settings.chartStyle === ChartStyle.Company) {
            designSettingsValue = ORGANIZATION_STYLE_PREFIX + settings.selectedOrganizationStyleId;
        }
        designSettingsDiv.node().append(select({
            id: "style-selector",
            label: "Style",
            name: "chartStyle",
            type: "string",
            value: designSettingsValue,
            options: [
                <InputOption>{ title: "Zebra", value: `${ChartStyle.Zebra}`, default: true },
                <InputOption>{ title: "Zebra Light", value: `${ChartStyle.ZebraLight}` },
                <InputOption>{ title: "Dr. Hichert", value: `${ChartStyle.DrHichert}` },
                <InputOption>{ title: "Power BI", value: `${ChartStyle.PowerBI}` },
                <InputOption>{ title: "Colorblind-friendly", value: `${ChartStyle.ColorblindSafe}` },
                <InputOption>{ title: "Custom", value: `${ChartStyle.Custom}` },
                ...orgStyleInputOptions
            ]
        }));

        let styleCustomDiv = designSettingsDiv.append(DIV);
        styleCustomDiv.classed("styleCustom", true);
        styleCustomDiv.classed("hidden", settings?.chartStyle != ChartStyle.Custom);

        let mainColorsSettings = [
            { name: `Positive`, settingsProperty: `positiveColor` },
            { name: `Negative`, settingsProperty: `negativeColor` },
            { name: `Neutral`, settingsProperty: `neutralColor` },
            { name: `Markers`, settingsProperty: `markerColor` },
            { name: `Lines`, settingsProperty: `lineColor` },
            { name: `Axis`, settingsProperty: `axisColor` },
            { name: `Minor Gridline`, settingsProperty: `gridlineColor` },
            { name: `Major Gridline`, settingsProperty: `majorGridlineColor` },
            { name: `Highlight`, settingsProperty: `highlightColor` }
        ];
        this.populateColorPickers(styleCustomDiv, settings, mainColorsSettings);

        // more colors
        designSettingsDiv.node().append(input({
            id: "use-more-colors-id",
            label: "Use more colors",
            name: "colorScheme.useCustomScenarioColors",
            value: "true",
            type: "checkbox",
            hidden: settings.chartStyle != ChartStyle.Custom,
            checked: settings?.colorScheme.useCustomScenarioColors
        }));

        let moreColorsSettings = [
            { name: `Plan`, settingsProperty: `planColor` },
            { name: `Plan2`, settingsProperty: `plan2Color` },
            { name: `Plan3`, settingsProperty: `plan3Color` },
            { name: `Previous Year`, settingsProperty: `previousYearColor` },
            { name: `Forecast`, settingsProperty: `forecastColor` }
        ];

        let moreColorsDiv = designSettingsDiv.append(DIV);
        moreColorsDiv.classed("hidden", !settings?.colorScheme.useCustomScenarioColors);
        this.populateColorPickers(moreColorsDiv, settings, moreColorsSettings);

        // custom design settings
        moreColorsDiv.node().append(input({
            id: "apply-patterns-id",
            label: "Apply patterns",
            name: "colorScheme.applyPatterns",
            value: "true",
            type: "checkbox",
            checked: settings?.colorScheme.applyPatterns,
            hidden: settings?.chartStyle != ChartStyle.Custom
        }));

        // variance display type
        designSettingsDiv.node().append(select({
            id: "variance-selector",
            label: "Variance display type",
            name: "varianceDisplayType",
            type: "number",
            options: [
                <InputOption>{ title: "Bar", value: `${VarianceDisplayType.Bar}`, default: true },
                <InputOption>{ title: "Arrow", value: `${VarianceDisplayType.Chevron}` },
            ],
            value: `${settings?.varianceDisplayType}`
        }));

        // lighten overlapped
        designSettingsDiv.node().append(input({
            checked: settings?.lightenOverlapped,
            id: "lighten-overlapped-id",
            label: "Lighten overlapped",
            name: "lightenOverlapped",
            type: "checkbox",
            value: "true"
        }));

        this.observeFormChanges(innerForm.node());
    }

    private populateColorPickers(divContainer: d3.Selection<HTMLDivElement, any, any, any>, settings: VarianceSettings, colorSettings: any) {
        colorSettings.forEach(colorSetting => {
            let colorID = colorSetting.name.replace(` `, ``);
            let color = settings?.colorScheme[colorSetting.settingsProperty] || BLACK;

            divContainer.append(P).text(colorSetting.name);
            let colorContainer = divContainer.append(DIV);
            colorContainer.classed("customColorContainer", true);

            let customColorBoxDiv = colorContainer.append(DIV);
            customColorBoxDiv.attr("id", `${colorID}ColorPicker`);
            customColorBoxDiv.classed("color", true);
            customColorBoxDiv.style("background-color", color);

            customColorBoxDiv.node().append(input({
                hidden: true,
                id: `${colorID}ColorPickerInput`,
                label: "",
                name: `colorScheme.${colorSetting.settingsProperty}`,
                type: "hidden",
                value: color
            }));

            let arrowEl = document.createElement(DIV);
            arrowEl.classList.add("arrow");
            colorContainer.node().append(arrowEl);
            colorContainer.on("click", () => {
                this.getCustomColorPicker(`${colorID}ColorPicker`, color);
            });
        });
    }

    getValidChartLayouts(): Map<string, InputOption> {
        return new Map<string, InputOption>([
            [ACT_ABS_REL, {
                title: "Responsive",
                value: ACT_ABS_REL
            }],
            [INTEGRATED, {
                title: `${INTEGRATED} variance`,
                value: INTEGRATED
            }],
            [ABSOLUTE, {
                title: `${ABSOLUTE} variance`,
                value: ABSOLUTE
            }],
            [RELATIVE, {
                title: `${RELATIVE} variance`,
                value: RELATIVE
            }],
            [ABSOLUTE_RELATIVE, {
                title: `${ABSOLUTE_RELATIVE} variance`,
                value: ABSOLUTE_RELATIVE
            }],
            [ACTUAL_ABSOLUTE, {
                title: `${ACTUAL_ABSOLUTE} variance`,
                value: ACTUAL_ABSOLUTE
            }],
            [ACTUAL_RELATIVE, {
                title: `${ACTUAL_RELATIVE} variance`,
                value: ACTUAL_RELATIVE
            }],
            [ACTUAL, {
                title: `${ACTUAL} variance`,
                value: ACTUAL
            }]
        ]);
    }


}

export default SettingsTablesSwitcher;
